const LANDING = '/';
const SIGN_UP = '/signup';
const SIGN_IN = '/signin';
const HOME = '/app';
const FORGET_PASSWORD = '/forget-password';
const VERIFY_EMAIL = '/verify-email';
const SIGN_OUT = 'signout';
const CAMPAIGN_DETAIL = '/campaign';
const MY_DASHBOARD = '/my-dashboard';
const MY_FLOWERS = 'my-designs';
const CREATORS = 'creators';
const LIVE_STREAMERS = 'live-streamers';
const TERMS_CONDITIONS = 'tos';
const PRIVACY = 'privacy';
const COOKIE = 'cookie';
const REFUND = 'refund';
const APP = '/app';
const AGENCIES= '/agencies'
const AFFILIATE = '/affiliate'
const X = '/x'
const LINKEDIN = '/linkedin'
const YEARBOOK = '/how-to-get-ai-yearbook-for-free'
const AIHEADSHOT_GENERATOR = '/ai-headshot-generator'
const AIINFLUENCERS = '/ai-influencers'
const AISHORTS = '/ai-shorts'
const AIHEADSHOT = '/free-trial'
const LEGAL = '/legal'
const DATING_PHOTOS = '/dating-photos'
const STUDENT_HEADSHOTS = '/student-headshots'
const BUSINESS_HEADSHOTS = '/businesses-headshots'
const HEADSHOTS_FOR_COMPANY = 'headshots-for-company'

export { 
    LANDING, 
    SIGN_UP,
    SIGN_IN, 
    APP, 
    HOME, 
    SIGN_OUT, 
    AFFILIATE, 
    VERIFY_EMAIL, 
    LEGAL, 
    DATING_PHOTOS, 
    STUDENT_HEADSHOTS, 
    BUSINESS_HEADSHOTS, 
    X, 
    LINKEDIN, 
    YEARBOOK, 
    AIHEADSHOT,
    AIHEADSHOT_GENERATOR,
    AIINFLUENCERS,
    AISHORTS,
    HEADSHOTS_FOR_COMPANY
};
