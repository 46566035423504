import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n/index';
import { Link } from 'react-router-dom';
import FAQ from '../../FAQ';
import BeforeAfter from '../../BeforeAfter';
import PricingLandingPage from '../../PricingLandingPage';
import CookieConsent from '../../CookieConsent';
import GiveawayModal from '../../GiveAway';
import QuickSignUpEmailAndGoogleContainer from '../../../containers/QuickSignUpEmailAndGoogleContainer';
import PhotoPipeLine from '../../PhotoPipeLine';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

const AIInfluencers = ({ engine }) => {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.user);

  const [currentLanguage, setCurrentLanguage] = useState('');
  const [imageIndex, setImageIndex] = useState(0);
  const [isShowLoginWithGoogle, setIsShowLoginWithGoogle] = useState(false);
  const [isShowLoginWithEmailLink, setIsShowLoginWithEmailLink] = useState(false); // For unsupported browsers
  const [redirectPaymentUrl, setRedirectPaymentUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [darkMode, setDarkMode] = useState(true);
  const [showPhotoPipeLine, setShowPhotoPipeLine] = useState(false);
  const [selectedPhotoPack, setSelectedPhotoPack] = useState(null);

  const urls = [
    // 'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2Fnew-landing-3.webp?alt=media&token=8536dcff-f6cb-4d53-b9c6-58eedd4d5772',
    // 'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2Fnew-landing-tech.webp?alt=media&token=40f6ee35-bec9-4608-b961-2e015ad6dbed',
    'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2Fai-makeup.webp?alt=media&token=4270a0ed-4557-4f70-92b4-320f5c3e67dd',
    'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2Fnew-landing-fashion.webp?alt=media&token=4fcae430-795f-4c8c-b7a1-c489aae2241d',
    // 'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2Ftraveling.webp?alt=media&token=a18b24fe-0ca4-4f51-9f1f-ad889fa04476',
    // 'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2Fnew-landing-thumbnail.webp?alt=media&token=873e402e-95da-4032-a2c2-3e92df5c9e6e'
  ];

  const exampleImages = [
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-9zj3wz4pe9rme0ck53yb0ewfxc_0.jpg?generation=1731559516596679&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-1kd65kverdrme0ck53z8xts6vw_1.jpg?generation=1731559656762935&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-98kqq12pzsrma0ck4qfa0y51hc_3.jpg?generation=1731507223039500&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-z8r6wb9eexrm40cjxj89njcrk4_2.jpg?generation=1730545763899024&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-pyp23ceatnrm20cjwpfarw86a4_3.jpg?generation=1730429318216779&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-pyp23ceatnrm20cjwpfarw86a4_2.jpg?generation=1730429319435547&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-5yab52zyqhrm00cjwndv9m8hh0_0.jpg?generation=1730424938359692&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-z4sa7vxj0hrm40cjw9ervdv138_2.jpg?generation=1730374717660419&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-82rn2fqd3srm60cjw9ct19sbfr_3.jpg?generation=1730374508361406&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-1bvtpkas5hrm20cjw97b3m75h8_3.jpg?generation=1730373717174617&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-xj0dq8146hrm20cjw7jb6tp494_0.jpg?generation=1730366751685269&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-fajqtp3b01rm20cjvk2rfkgahr_2.jpg?generation=1730280857464762&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-9qnh8ngh09rm40cjvgw8pddmd0_2.jpg?generation=1730271590711341&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-a9mj4cf5xxrm60cjvgkvw9jdkw_0.jpg?generation=1730270528349868&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-nf6kt1ahqnrmc0ck3eyvqjhpym_2.jpg?generation=1731337281164717&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-51v8bny331rme0ck3extd8r4zw_2.jpg?generation=1731337188049181&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-0f9akj1tv9rmc0ck3c2bhcfkq8_2.jpg?generation=1731325355694864&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-ze51jnznqxrmc0ck3c08we6kt8_1.jpg?generation=1731324936323287&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-ze51jnznqxrmc0ck3c08we6kt8_3.jpg?generation=1731324936371616&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-0e324zq9ksrmc0ck3bzbsj69zw_3.jpg?generation=1731324824860188&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-0e324zq9ksrmc0ck3bzbsj69zw_0.jpg?generation=1731324825110355&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-djgb2f58qsrme0ck2tzvje0pqw_3.jpg?generation=1731253556021214&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-yc7cfmqg5srma0ck2tx9kpf6c0_1.jpg?generation=1731253254554879&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-83zyqwwb7nrma0ck0vybstv814_0.jpg?generation=1730989168319819&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-f6hxr8wtzsrmc0ck0tys8xzff8_3.jpg?generation=1730984983366851&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-w479w0d4axrme0ck0txb0zx3cc_3.jpg?generation=1730984792365740&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-j7qaf5nk8drm00cjzahsabzj0c_1.jpg?generation=1730781965354533&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-f9gsxryj8drm40cjz9qa21ncv0_2.jpg?generation=1730778492044292&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-yzyyfnzd49rm20cjz9msf49vmg_3.jpg?generation=1730778288348759&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-1vat5wr9t9rm40cjz9mv7s6hrm_3.jpg?generation=1730778086578487&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-s2za1tm77srm00cjz9kv41tgf4_2.jpg?generation=1730778014018477&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-jgvy91rqhnrm00cjz9kbqs3qwr_3.jpg?generation=1730777928030748&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-y4s96g34fdrm60cjz818hcxn54_3.jpg?generation=1730771386130066&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-etb0f3fd0hrm20cjz7z9mq5zq8_2.jpg?generation=1730771168321892&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-ksdq9mwqnxrm60cjyt6sm4ghgw_3.jpg?generation=1730713400272026&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-q80850a4hnrm20cjyt68z5f4rr_3.jpg?generation=1730713313589843&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-113qtcc571rm40cjyt3br55y68_2.jpg?generation=1730712936814834&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-mq80mmxyjsrm00cjxzq92eazfw_2.jpg?generation=1730602331477746&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-dcgpjazjnxrm20cjxmdsc5hgcw_3.jpg?generation=1730554962925059&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-vw3wmz24r1rm60cjxm7a9qrtk8_3.jpg?generation=1730554065215855&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-ttbegky1q1rm60cjxk0a42azcr_0.jpg?generation=1730548994500285&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-ycsdc88w69rm20cjxjzr2xdqsr_2.jpg?generation=1730548876511086&alt=media',
    // 'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-ctpr14vjfsrm60cjxjmt2g1e1r_0.jpg?generation=1730547456029413&alt=media',
    // 'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-d454stpthxrm60cjxja9dedt70_3.jpg?generation=1730546107232867&alt=media'
];

  const trendingPacks = [
    {
      id: 'business_headshots',
      title: 'Professional headshots',
      description: 'Instantly generate polished professional headshots',
      image:
        'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/admin%2Ffiles%2Fbusiness.png?alt=media&token=61d967e3-a999-4e7f-96b5-370f52566f0b',
      isHot: true,
      category: ['for_business', 'trending'],
      prompt: '',
      createdAt: '2024-09-13T14:03:54.874Z',
      active: true,
      showOnLanding: true,
    },
    {
      id: 'halloween',
      title: 'Halloween photos',
      description: 'Halloween is coming! Transform yourself in Halloween costume',
      image:
        'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/admin%2Ffiles%2Fhaloween.webp?alt=media&token=388a3b31-2967-4bef-85c1-f66f1d678de0',
      isHot: true,
      category: ['for_creator', 'trending'],
      prompt: '',
      createdAt: '2024-09-25T14:32:37.514Z',
      active: true,
      showOnLanding: true,
    },
    {
      id: 'tech_conference',
      title: 'Tech conference',
      description: 'Take polished professional photos at Conference',
      image:
        'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/photoPacks%2Fconference.png?alt=media&token=a85303f1-31a6-479b-bbfb-d1dc2df7507d',
      isHot: false,
      category: ['trending'],
      prompt: '',
      createdAt: '2024-09-13T14:03:54.874Z',
      active: true,
      showOnLanding: true,
    },
    {
      id: 'travel_photos',
      title: 'Travel photos',
      description: 'Imagine any where you go to travel with AI',
      image:
        'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/admin%2Ffiles%2Ftravel.png?alt=media&token=685cc691-8ce8-4aee-9f42-8eab157d8138',
      isHot: false,
      category: ['trending'],
      prompt: '',
      createdAt: '2024-09-13T14:03:54.874Z',
      active: true,
      showOnLanding: true,
    },
    // {
    //   id: "holographic_fashion",
    //   title: "Holographic fashion",
    //   description: "Holographic fashion",
    //   image: "https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/admin%2Ffiles%2Fholographic-fashion.png?alt=media&token=f8d94b4b-2646-4118-a599-2d3e8c72fc99",
    //   isHot: false,
    //   category: ["for_creator", "trending"],
    //   prompt: "a model in holographic style, wearing shiny holographic fashion",
    //   createdAt: "2024-09-25T14:32:37.514Z",
    //   active: true,
    //   showOnLanding: true
    // }
  ];

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  // const userPreferredLanguage = navigator.language || navigator.userLanguage;

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const refParam = urlSearchParams.get('ref');
    const affParam = urlSearchParams.get('aff');

    if (refParam) {
      localStorage.setItem('refCode', refParam);
    } else {
      localStorage.setItem('refCode', '');
    }

    if (affParam) {
      localStorage.setItem('affCode', affParam);
    } else {
      localStorage.setItem('affCode', 'reok');
    }

    const redirect = urlSearchParams.get('r');

    if (redirect) {
      localStorage.setItem('redirect', redirect);
    } else {
      localStorage.setItem('redirect', '');
    }

    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setImageIndex(prevIndex => (prevIndex + 1) % urls.length);
    }, 2000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isShowLoginWithGoogle && (userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)))
        setShowModal(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, [isShowLoginWithGoogle, userData]);

  function showLogin() {
    setIsShowLoginWithGoogle(true);
  }

  function showLoginWithOutPayment() {
    setRedirectPaymentUrl('app/?pack=ai_influencer');

    // const redirect = localStorage.getItem('redirect');
    // if (redirect === "fb") {
    //   setIsShowLoginWithEmailLink(true);
    //   return;
    // }
    setIsShowLoginWithGoogle(true);
  }

  function onShowLogin(isShow) {
    setIsShowLoginWithGoogle(false);
    setIsShowLoginWithEmailLink(false);
  }

  function showLoginForPayment(redirectUrl) {
    setRedirectPaymentUrl(redirectUrl);

    const redirect = localStorage.getItem('redirect');
    if (redirect === 'fb') {
      setIsShowLoginWithEmailLink(true);
      return;
    }
    setIsShowLoginWithGoogle(true);
  }

  const handleSubmitted = submitted => {
    setIsSubmitted(submitted);
  };

  const openPhotoPipeLine = (e, item) => {
    e.preventDefault();

    if (userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)) {
      setSelectedPhotoPack(item);
      setShowPhotoPipeLine(true);
    } else {
      window.location.href = `${currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`}/?pack=${item.id}`;
    }
  };

  const closePhotoPipeLine = () => {
    setShowPhotoPipeLine(false);

    if (userData === null || userData === undefined) {
      setIsShowLoginWithGoogle(true);
    }
  };

  // const onLoginFirst = () =>  {
  //   setTimeout(() => {
  //     setIsShowLoginWithGoogle(true);
  //     setShowPhotoPipeLine(false);
  //   }, 1000);
  // }

  return (
    <div>
      <main className="bg-black">
        <div
          style={{
            backgroundImage: "url('/images/REOKSTUDIO.webp')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
          }}
        >
          <div className="landing-cover-header" data-controller="gradient-animation">
            <div className="flex flex-wrap justify-content-evenly lg:w-1/2">
              <div className="lg:w-1/2 px-2">
                <div className="landing-order-first flex items-center">
                  {/* Content for the first column */}
                  <div className="left-landing-wp">
                    <div className="title-landing-wrapper">
                      <div className="text-root-0-2-141 text-landing-title banner-landing-title banner-landing-block-title">
                        <span>
                          <h1 className="display-title-landing fw-bold text-white">AI Influencer Generator</h1>
                        </span>
                      </div>
                      <div className="sub-title-landing" style={{ maxWidth: '490px' }}>
                        <span style={{ color: '#adb5bd' }}>
                            Perfect for marketing campaigns, social media content, ads and more. Generate unlimited unique looks and styles that resonate with your target audience in seconds.
                        </span>
                      </div>
                      <div className="quick-sign-up-mobile mt-5">
                        <div className="upgrade-btn-wrapper">
                          {userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading) ? (
                            <div onClick={showLoginWithOutPayment} className="upgrade-button">
                              Start your AI Influencer
                              <span style={{ marginLeft: '4px' }}>→</span>
                            </div>
                          ) : (
                            <Link to={currentLanguage === 'en' ? '/app/?pack=ai_influencer' : `/${currentLanguage}/app/?pack=ai_influencer`} className="upgrade-button">
                              Start your AI Influencer
                              <span style={{ marginLeft: '4px' }}>→</span>
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className="customer-demo-wrapper">
                        <div className=" relative mx-auto mt-6 space-y-4">
                          <div className="flex items-center gap-3">
                            <div className="flex -space-x-3 overflow-hidden">
                              <img
                                src="/images/customer13.webp"
                                alt=""
                                className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                                width={40}
                                height={40}
                              />{' '}
                              <img
                                style={{ marginLeft: '-12px' }}
                                src="/images/customer16.webp"
                                alt=""
                                className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                                width={40}
                                height={40}
                              />{' '}
                              <img
                                style={{ marginLeft: '-12px' }}
                                src="/images/customer17.webp"
                                alt=""
                                className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                                width={40}
                                height={40}
                              />{' '}
                              <img
                                style={{ marginLeft: '-12px' }}
                                src="/images/customer11.webp"
                                alt=""
                                className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                                width={40}
                                height={40}
                              />{' '}
                              <img
                                style={{ marginLeft: '-12px' }}
                                src="/images/customer19.webp"
                                alt=""
                                className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                                width={40}
                                height={40}
                              />
                            </div>{' '}
                            <div className="flex" style={{ color: '#facc15' }}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>{' '}
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>{' '}
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>{' '}
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>{' '}
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                            </div>
                          </div>{' '}
                          <p className="text-sm text-gray-500">{t('landing.loveBy')}</p>
                        </div>
                      </div>
                      <div className="banner-block-ctaHolder-d126-0-2-398  mt-4">
                        <div className="upgrade-btn-wrapper">
                          {userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading) ? (
                            <div onClick={showLoginWithOutPayment} className="upgrade-button">
                              Start your AI Influencer
                              <span style={{ marginLeft: '4px' }}>→</span>
                            </div>
                          ) : (
                            <Link to={currentLanguage === 'en' ? '/app/?pack=ai_influencer' : `/${currentLanguage}/app/?pack=ai_influencer`} className="upgrade-button">
                              Start your AI Influencer
                              <span style={{ marginLeft: '4px' }}>→</span>
                            </Link>
                          )}
                        </div>
                        <span className="text-gray-500">OR</span>
                        <div className="upgrade-btn-wrapper">
                          <a className="enterprise-button" href="/headshots-for-company" target="_">
                            {t('button.forEnterprise')}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-1/2 px-2 mt-4">
                <div className="landing-order-second">
                  <div className="banner-block-mediaWrapper">
                    <div className="before-after-landing-wrapper">
                      <div className="video-slider">
                        <div className="banner-video-landing flex flex-col items-center">
                          <img loading="lazy" className="flex justify-center" alt="reok" width={367} height={313} src={urls[imageIndex]} />
                          {/* <BeforeAfterGallery images={urls}/> */}
                          <div className="flex justify-center mt-2">
                            <div className="w-full flex justify-center">
                              <div className="text-align-center text-sm text-gray-500 pl-5 pr-5 hide-sub-title">
                                {t('landing.subTitle1')}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center text-center relative as-seen-on-wrapper">
              <span className="text-gray-500">as seen on</span>
              <img className="tiktok-seen-on" loading="lazy" alt="reok" src="images/tiktok.webp" />
              <img className="facebook-seen-on" loading="lazy" alt="reok" src="images/facebook.svg" />
              <img className="instagram-seen-on" loading="lazy" alt="reok" src="images/instagram.webp" />
              <img className="linkedin-seen-on" loading="lazy" alt="reok" src="images/linkedin.png" />
              <img className="x-seen-on" loading="lazy" alt="reok" src="images/x.webp" />
              <img className="reddit-seen-on" loading="lazy" alt="reok" src="images/reddit.webp" />
            </div>
            {/* <div className="flex items-center justify-center text-center relative">
            <MoneyBackGuarantee/>
          </div> */}
          </div>
        </div>
      </main>
      <main>
        {/* Before After slider */}
        <section className="bg-black relative overflow-hidden pt-5">
          {/* <BeforeAfterTestimonial /> */}
          <Box
            sx={{
              width: '100%',
              height: 280,
              display: 'flex',
              position: 'relative',
              backgroundColor: '#000',
              overflow: 'hidden',
              willChange: 'transform',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                animation: 'slideImages 80s linear infinite',
                willChange: 'transform',
                transform: 'translate3d(0, 0, 0)',
                backfaceVisibility: 'hidden',
                '@keyframes slideImages': {
                  '0%': {
                    transform: `translate3d(calc(-280px * ${exampleImages.length}), 0, 0)`,
                  },
                  '100%': {
                    transform: 'translate3d(0, 0, 0)',
                  },
                  '0%': {
                    transform: `translate3d(calc(-280px * ${exampleImages.length}), 0, 0)`,
                  },
                },
                '&:hover': {
                  animationPlayState: 'paused',
                },
              }}
            >
              {[...exampleImages, ...exampleImages, ...exampleImages].map((image, index) => (
                <Box
                  key={index}
                  sx={{
                    position: 'relative',
                    width: '180px',
                    // height: '280px',
                    flexShrink: 0,
                  }}
                >
                  {/* <Skeleton
                    variant="rectangular"
                    width={280}
                    height={280}
                    animation="wave"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      bgcolor: darkMode ? 'grey.900' : 'grey.200',
                      display: 'block',
                    }}
                  /> */}
                  <Box
                    component="img"
                    src={image}
                    loading="lazy"
                    decoding="async"
                    // fetchPriority={index < exampleImages.length ? "high" : "low"}
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      willChange: 'transform',
                      transform: 'translate3d(0, 0, 0)',
                      WebkitFontSmoothing: 'antialiased',
                      transition: 'transform 0.3s ease',
                      opacity: 0,
                      borderRadius: '10px',
                      animation: 'fadeIn 0.5s ease forwards',
                      '@keyframes fadeIn': {
                        to: { opacity: 1 }
                      },
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                    }}
                    // onLoad={(e) => {
                    //   e.target.style.opacity = '1';
                    //   const skeleton = e.target.previousSibling;
                    //   if (skeleton) {
                    //     skeleton.style.display = 'none';
                    //   }
                    // }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </section>

        {/* How it works */}
        <section className="bg-black relative overflow-hidden section-top-90">
          <div className="space-y-8 md:space-y-[85px]">
            <div className="container">
              <div className="w-full" id="how-it-works">
                <div className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 label-wrapper">
                  <span>3 simple steps</span>
                </div>
                <h2 className="mx-auto relative z-[10] t-50 text-white mb-3 md:mb-6 font-bold text-center mx-auto md:text-center">
                  How AI Influencer Works
                </h2>
              </div>
              <div
                className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-6 md:mb-6"
                style={{ maxWidth: '722px' }}
              >
                <p className="text-lg">
                    Generate unique virtual influencers for your ads, social medias, and more
                </p>
              </div>
              <div className="space-y-8">
                <ul className="cards">
                  <li className="cards_item">
                    <div className="card">
                      <div className="card_content" style={{ maxWidth: '350px' }}>
                        <div className="w-full h-20 flex items-center justify-center">
                          <div className="relative items-center justify-center inline-block p-4 px-5 py-3 overflow-hidden font-medium text-indigo-600 rounded-lg shadow-2xl group">
                            <span className="absolute top-0 left-0 w-40 h-40 -mt-10 -ml-3 transition-all duration-700 bg-red-500 rounded-full blur-md ease"></span>
                            <span className="absolute inset-0 w-full h-full transition duration-700 group-hover:rotate-180 ease">
                              <span className="absolute bottom-0 left-0 w-24 h-24 -ml-10 bg-pink-500 rounded-full blur-md"></span>
                              <span className="absolute bottom-0 right-0 w-24 h-24 -mr-10 bg-pink-500 rounded-full blur-md"></span>
                            </span>
                            <span className="relative text-white t-30">1</span>
                          </div>
                        </div>
                        <h2 className="card_title text-align-center mt-4">Select your model</h2>
                        <p className="card_text text-align-center text-gray-500">
                            You choose a model that matches your brand and target audience, or train your own model.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="cards_item">
                    <div className="card">
                      <div className="card_content" style={{ maxWidth: '350px' }}>
                        <div className="w-full h-20 flex items-center justify-center">
                          <div className="relative items-center justify-center inline-block p-4 px-5 py-3 overflow-hidden font-medium text-indigo-600 rounded-lg shadow-2xl group">
                            <span className="absolute top-0 left-0 w-40 h-40 -mt-10 -ml-3 transition-all duration-700 bg-gray-800 rounded-full blur-md ease"></span>
                            <span className="absolute inset-0 w-full h-full transition duration-700 group-hover:rotate-180 ease">
                              <span className="absolute bottom-0 left-0 w-24 h-24 -ml-10 bg-gray-500 rounded-full blur-md"></span>
                              <span className="absolute bottom-0 right-0 w-24 h-24 -mr-10 bg-black-500 rounded-full blur-md"></span>
                            </span>
                            <span className="relative text-white t-30">2</span>
                          </div>
                        </div>
                        <h2 className="card_title text-align-center mt-4">{t('landing.step2')}</h2>
                        <p className="card_text text-align-center text-gray-500">
                            Instantly creates stunning AI Influencer photos based on your prompt in just seconds.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="cards_item">
                    <div className="card">
                      <div className="card_content" style={{ maxWidth: '350px' }}>
                        <div className="w-full h-20 flex items-center justify-center">
                          <div className="relative items-center justify-center inline-block p-4 px-5 py-3 overflow-hidden font-medium text-indigo-600 rounded-lg shadow-2xl group">
                            <span className="absolute top-0 left-0 w-40 h-40 -mt-10 -ml-3 transition-all duration-700 bg-yellow-500 rounded-full blur-md ease"></span>
                            <span className="absolute inset-0 w-full h-full transition duration-700 group-hover:rotate-180 ease">
                              <span className="absolute bottom-0 left-0 w-24 h-24 -ml-10 bg-yellow rounded-full blur-md"></span>
                              <span className="absolute bottom-0 right-0 w-24 h-24 -mr-10 bg-yellow-500 rounded-full blur-md"></span>
                            </span>
                            <span className="relative text-white t-30">3</span>
                          </div>
                        </div>
                        <h2 className="card_title text-align-center mt-4">{t('landing.step3')}</h2>
                        <p className="card_text text-align-center text-gray-500">{t('landing.step3Sub')}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="space-y-8 md:space-y-[85px]">
            <div className="container">
              <div className="container rnftg-h-full rnftg-w-full mt-4">
                <div className="rnftg-h-full dark:rnftg-bg-gray-900">
                  <div className="rnftg-flex rnftg-flex-col pd-2">
                    <div className="rnftg-grid rnftg-gap-custom rnftg-grid-flow-row rnftg-grid-cols-3 md:rnftg-grid-cols-3 lg:rnftg-grid-cols-6 xl:rnftg-grid-cols-6">
                      {exampleImages.map((url, index) => (
                        <article
                          key={index}
                          className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300"
                        >
                          <div className="rnftg-item__img-wrapper_landing">
                            <div className="landing-result">
                              <img className="banner-video" alt="reok" src={url}></img>
                            </div>
                          </div>
                        </article>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Redesign with any Style */}
        <section className="relative overflow-hidden bg-black section-top-180 section-bottom-90" data-layouts="text_card,featured_cards">
          <div className="container">
            <div className="flex flex-wrap justify-content-evenly w-1/2 md:w-full">
              <div className="card-w">
                <div className="w-full p-2">
                  <div className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 label-wrapper">
                    <span>{t('landing.upscaleShowCase.label')}</span>
                  </div>
                  <h2
                    className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-3 md:mb-6 font-bold text-center mx-auto md:text-center"
                    style={{ maxWidth: '722px' }}
                  >
                    {t('landing.upscaleShowCase.title')}
                  </h2>
                  <div
                    className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-6 md:mb-6"
                    style={{ maxWidth: '722px' }}
                  >
                    <p className="text-lg">{t('landing.upscaleShowCase.subTitle')}</p>
                  </div>
                  <div className="flex flex-col align-items-center mb-6">
                    <div className="getstarted-btn-wrapper">
                      {userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading) ? (
                        <div onClick={showLoginWithOutPayment} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>→</span>
                        </div>
                      ) : (
                        <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>→</span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-w">
                <div className="flex justify-center">
                  <BeforeAfter
                    key={1}
                    images={{
                      before:
                        'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2Fupscale-before.webp?alt=media&token=c54e73f1-d492-4096-8713-ada61ef22d0d',
                      after:
                        'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2Fupscale-after.webp?alt=media&token=481cfabb-bbe6-4f0a-8f72-85c3a92567ba',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Remove anything */}
        <section className="relative overflow-hidden bg-black section-top-90 section-bottom-90" data-layouts="text_card,featured_cards">
          <div className="container">
            <div className="flex flex-wrap justify-content-evenly w-1/2 md:w-full">
              <div className="card-w landing-order-second">
                <div className="flex justify-center">
                  <BeforeAfter
                    images={{
                      before:
                        'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2Fclean-before.webp?alt=media&token=a3936b8b-dd93-420e-9f47-f29bd9887173',
                      after:
                        'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2Fclean-after.webp?alt=media&token=a7e44c7e-fd65-4a9e-9a6a-b45121eddacf',
                    }}
                  />
                </div>
              </div>
              <div className="card-w landing-order-first">
                <div className="w-full p-2">
                  <div className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 label-wrapper">
                    <span>{t('landing.magicEraserShowCase.label')}</span>
                  </div>
                  <h2
                    className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-3 md:mb-6 font-bold text-center mx-auto md:text-center"
                    style={{ maxWidth: '722px' }}
                  >
                    {t('landing.magicEraserShowCase.title')}
                  </h2>
                  <div
                    className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-6 md:mb-6"
                    style={{ maxWidth: '722px' }}
                  >
                    <p className="text-lg">{t('landing.magicEraserShowCase.subTitle')}</p>
                  </div>
                  <div className="flex flex-col align-items-center mb-6">
                    <div className="getstarted-btn-wrapper">
                      {userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading) ? (
                        <div onClick={showLoginWithOutPayment} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>→</span>
                        </div>
                      ) : (
                        <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>→</span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Show customers feedback section */}
        {/* <section
          className="relative overflow-hidden bg-black section-top-none section-top-90 section-bottom-90"
        >
          <Testimonial t={t} />
        </section> */}
        {/* Pricing section */}
        <section className="bg-neutral-black section-top-90 section-bottom-90">
          <PricingLandingPage
            t={t}
            currentLanguage={currentLanguage}
            userData={userData}
            showLoginForPayment={showLoginForPayment}
            showLoginWithOutPayment={showLoginWithOutPayment}
            showHeadshotPlan={false}
          />
        </section>
        {/* FAQ section */}
        <section className="bg-neutral-black section-bottom-90">
          <div className="relative bg-neutral-black mb-4">
            <div className="container">
              <FAQ t={t} />
            </div>
          </div>
        </section>
        <section
          className="bg-[#101014] bg-blend-luminosity bg-no-repeat bg-center bg-cover h-[50vh] flex items-center justify-center"
          style={{
            backgroundImage: "linear-gradient(360deg, rgb(13, 13, 13) 0%, rgba(16, 16, 20, 30%) 100%), url('/images/footer-cover.webp')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="container mx-auto px-4 call-to-action">
            <div className="flex flex-col items-center text-center">
              <h2
                className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-6 md:mb-6 font-bold text-center mx-auto md:text-center"
                style={{ maxWidth: '722px' }}
              >
                {t('landing.joinNow')}
              </h2>
              <div className="getstarted-btn-wrapper">
                {userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading) ? (
                  <div onClick={showLoginWithOutPayment} className="upgrade-button">
                    {t('button.try')}
                    <span style={{ marginLeft: '4px' }}>→</span>
                  </div>
                ) : (
                  <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                    {t('button.try')}
                    <span style={{ marginLeft: '4px' }}>→</span>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
      {isShowLoginWithGoogle && (
        <QuickSignUpEmailAndGoogleContainer
          onShowLogin={onShowLogin}
          redirectPaymentUrl={redirectPaymentUrl}
          isSubmitted={isSubmitted}
          onSubmitted={handleSubmitted}
        />
      )}
      {/* {isShowLoginWithGoogle && <QuickSignUpLandingPageContainer onShowLogin={onShowLogin} redirectPaymentUrl={redirectPaymentUrl} />} */}
      <CookieConsent />
      <GiveawayModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        isSubmitted={isSubmitted}
        onSubmitted={handleSubmitted}
        currentLanguage={currentLanguage}
        t={t}
      />
      {showPhotoPipeLine && (
        <PhotoPipeLine
          onClose={closePhotoPipeLine}
          firebase={engine}
          darkMode={darkMode}
          userData={userData}
          t={t}
          photoPack={selectedPhotoPack}
        />
      )}
    </div>
  );
};

export default AIInfluencers;
