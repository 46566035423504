import React, { useEffect } from 'react';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import * as routes from '../constants/routes';
import HomeContainer from '../containers/HomeContainer';
import NotFound from '../components/NotFound';
import SignOutContainer from '../containers/SignOutContainer';
import AuthRoute from './AuthRoute';
import AnyRoute from './AnyRoute';
import PrivateRoute from './PrivateRoute'
import LandingPageContainer from '../containers/LandingPageContainer';
import TermsContainer from '../containers/TermsContainer';
import AppContainer from '../containers/AppContainer';
import i18n from '../i18n';
import Affiliate from '../components/Affiliate';
import VerifyEmailContainer from '../containers/VerifyEmailContainer';
import SignUpEmailAndGoogle from '../containers/SignUpEmailAndGoogleContainer';
import LandingBusinessesHeadshot from '../components/LandingBusinessesHeadshot';
import AIHeadshotGenerator from '../components/Headshots/AIHeadshotGenerator';
import AIInfluencers from '../components/Headshots/AIInfluencer';
import CompanyHeadshots from '../components/Headshots/CompanyHeadshots';

const LanguageRoute = () => {
  const { lang } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (lang === 'lemonsqueezy') {
      const mainLang = localStorage.getItem('mainLang');
      if (mainLang !== null) {
        navigate(`/${mainLang}/app/?purchase=success`);
      } else {
        navigate(`/app/?purchase=success`);
      }
      // setTimeout(() => {
      //   const mainLang = localStorage.getItem('mainLang');
      //   if (mainLang !== null) {
      //     navigate(`/${mainLang}/app/?purchase=success`);
      //   } else {
      //     navigate(`/app/?purchase=success`);
      //   }
      // }, 2000);
    }
    else if (['en', 'vi', 'id', 'jp', 'ko', 'zh'].includes(lang)) {
      i18n.changeLanguage(lang);

      const languageMetaTags = {
        en: {
          title: 'REOK AI Studio: Create Professional Headshots, Portraits, Products, and More',
          description: 'Create professional and realistic AI headshots and profile pictures using our online AI headshot photographer in seconds not hours.',
          meta: 'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2FREOK-STUDIO-META.webp?alt=media&token=61f8019a-8971-4927-a145-d3ad73b1b384'
        },
        vi: {
          title: 'Chụp ảnh chuyên nghiệp với AI',
          description: 'Tạo ảnh chuyên nghiệp, cải thiện chất lượng ảnh và nhiều tính năng khác với trí tuệ nhận tạo của chúng tôi.',
          meta: 'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2FREOK-STUDIO-META.webp?alt=media&token=61f8019a-8971-4927-a145-d3ad73b1b384'
        },
        zh: {
          title: '功能的AI工作室',
          description: '使用我们先进的AI摄影师创建专业照片，增强照片等。',
          meta: 'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2FREOK-STUDIO-META.webp?alt=media&token=61f8019a-8971-4927-a145-d3ad73b1b384'
        },
        id: {
          title: 'Studio AI untuk Fitur',
          description: 'Buat foto profesional, perbaiki foto, dan banyak lagi dengan fotografer AI canggih kami.',
          meta: 'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2FREOK-STUDIO-META.webp?alt=media&token=61f8019a-8971-4927-a145-d3ad73b1b384'
        },
        jp: {
          title: '機能のためのAIスタジオ',
          description: '高度なAIカメラマンを使用して、プロの写真を作成し、写真を向上させます。',
          meta: 'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2FREOK-STUDIO-META.webp?alt=media&token=61f8019a-8971-4927-a145-d3ad73b1b384'
        },
        ko: {
          title: '기능을 위한 AI 스튜디오',
          description: '고급 AI 포토그래퍼를 사용하여 전문적인 사진을 만들고 사진을 향상시킵니다.',
          meta: 'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2FREOK-STUDIO-META.webp?alt=media&token=61f8019a-8971-4927-a145-d3ad73b1b384'
        },
        // Add titles and descriptions for other languages as needed
      };

      const { title, description, meta } = languageMetaTags[lang];
      document.title = title;

      // Update Open Graph (og) meta tags
      const ogTitle = document.querySelector('meta[property="og:title"]');
      if (ogTitle) {
        ogTitle.setAttribute('content', title);
      }

      const ogDescription = document.querySelector('meta[property="og:description"]');
      if (ogDescription) {
        ogDescription.setAttribute('content', description);
      }

      const metaUrl = document.querySelector('meta[property="og:image"]');
      if (metaUrl) {
        metaUrl.setAttribute('content', meta);
      }

      const twitterTitle = document.querySelector('meta[name="twitter:title"]');
      if (twitterTitle) {
        twitterTitle.setAttribute('content', title);
      }

      const twitterDescription = document.querySelector('meta[name="twitter:description"]');
      if (twitterDescription) {
        twitterDescription.setAttribute('content', description);
      }

      const twitterUrl = document.querySelector('meta[name="twitter:image"]');
      if (twitterUrl) {
        twitterUrl.setAttribute('content', meta);
      }
    }
  }, [lang, navigate]);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<AnyRoute />}
        >
          <Route
            path={routes.LANDING}
            element={<LandingPageContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.LEGAL}
            element={<TermsContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          {/* <Route
            path={routes.PRIVACY}
            element={<PrivacyContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route> */}
          {/* <Route
            path={routes.REFUND}
            element={<RefundPolicy />}
          >
            <Route path="*" element={<NotFound />} />
          </Route> */}
          <Route
            path={routes.BUSINESS_HEADSHOTS}
            element={<LandingBusinessesHeadshot />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.AIHEADSHOT_GENERATOR}
            element={<AIHeadshotGenerator />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.HEADSHOTS_FOR_COMPANY}
            element={<CompanyHeadshots />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          {/* <Route
            path={routes.AISHORTS}
            element={<AIHeadshotGenerator />}
          >
            <Route path="*" element={<NotFound />} />
          </Route> */}
          <Route
            path={routes.AIINFLUENCERS}
            element={<AIInfluencers />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
        <Route
          path="/"
          element={<PrivateRoute />}
        >
          <Route
            path={routes.HOME}
            element={<HomeContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.APP}
            element={<AppContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
        <Route
          path="/"
          element={<AuthRoute />}
        >
          <Route
            path={routes.SIGN_IN}
            element={<SignUpEmailAndGoogle />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.SIGN_UP}
            element={<SignUpEmailAndGoogle />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route
            path={routes.SIGN_OUT}
            element={<SignOutContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
          {/* <Route
            path={routes.FORGET_PASSWORD}
            element={<ForgetPasswordContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route> */}
          <Route
            path={routes.VERIFY_EMAIL}
            element={<VerifyEmailContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
        {/* <Route
          path="/"
          element={<AdsRoute />}
        >
          <Route
            path={routes.AIHEADSHOT}
            element={<AdsLandingPageContainer />}
          >
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route> */}
      </Routes>
    </>
  );
};

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/:lang/*"
        element={<LanguageRoute />}
      />
      <Route
        path="/"
        element={<AnyRoute />}
      >
        <Route
          path={routes.LANDING}
          element={<LandingPageContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        {/* <Route
          path={routes.X}
          element={<XContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route> */}
        <Route
          path={routes.AFFILIATE}
          element={<Affiliate />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path={routes.LEGAL}
          element={<TermsContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        {/* <Route
          path={routes.PRIVACY}
          element={<PrivacyContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route> */}
        {/* <Route
          path={routes.REFUND}
          element={<RefundPolicy />}
        >
          <Route path="*" element={<NotFound />} />
        </Route> */}
        <Route
          path={routes.BUSINESS_HEADSHOTS}
          element={<LandingBusinessesHeadshot />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
            path={routes.AIHEADSHOT_GENERATOR}
            element={<AIHeadshotGenerator />}
          >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
            path={routes.HEADSHOTS_FOR_COMPANY}
            element={<CompanyHeadshots />}
          >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path={routes.AIINFLUENCERS}
          element={<AIInfluencers />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
      <Route
        path="/"
        element={<PrivateRoute />}
      >
        <Route
          path={routes.HOME}
          element={<HomeContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path={routes.APP}
          element={<AppContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
      <Route
        path="/"
        element={<AuthRoute />}
      >
        <Route
          path={routes.SIGN_IN}
          element={<SignUpEmailAndGoogle />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path={routes.SIGN_UP}
          element={<SignUpEmailAndGoogle />}
          >
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path={routes.SIGN_OUT}
          element={<SignOutContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
        {/* <Route
          path={routes.FORGET_PASSWORD}
          element={<ForgetPasswordContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route> */}
        <Route
          path={routes.VERIFY_EMAIL}
          element={<VerifyEmailContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
      {/* <Route
        path="/"
        element={<AdsRoute />}
      >
        <Route
          path={routes.AIHEADSHOT}
          element={<AdsLandingPageContainer />}
        >
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route> */}
    </Routes>
  );
};

export default AppRoutes;
