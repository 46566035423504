import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/index';
import { Link, useNavigate } from 'react-router-dom';
import FAQ from '../FAQ';
import BeforeAfter from '../BeforeAfter';
import PricingLandingPage from '../PricingLandingPage';
// import Testimonial from '../Testimonial';
// import BeforeAfterTestimonial from '../BeforeAfterTestimonial';
import CookieConsent from '../CookieConsent';
import GiveawayModal from '../GiveAway';
import QuickSignUpEmailAndGoogleContainer from '../../containers/QuickSignUpEmailAndGoogleContainer';
// import LandingPerfectHeadshot from '../LandingPerfectHeadshot';
import PhotoPipeLine from '../PhotoPipeLine';
import Box from '@mui/material/Box';
import { Grid, Card, CardContent, Typography, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import MovieFilterIcon from '@mui/icons-material/MovieFilter';
import OpenIconSpeedDial from '../OpenIconSpeedDial';
import AIPhotographyLanding from '../AIPhotographyLanding';

const StyledCard = styled(({ darkMode, ...props }) => <Card {...props} />)(({ darkMode }) => ({
  backgroundColor: darkMode ? '#1E1E1E' : '#FFFFFF',
  borderRadius: '12px',
  cursor: 'pointer',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
  },
}));

const icons = {
  AIHeadshots: <CenterFocusStrongIcon />,
  AIShorts: <MovieFilterIcon />,
  AIInfluencers: <FaceRetouchingNaturalIcon />,
};

const aiTools = [
  {
    title: 'AI Headshot',
    description: 'Professional AI-powered headshot photos in minutes',
    icon: icons.AIHeadshots,
    color: '#fc5185',
    callBtnName: 'Explore →',
    link: '/ai-headshot-generator',
    onClick: () => {
    },
  },
  {
    title: 'AI Influencers',
    description: 'Generate unique virtual influencers for your ads, social medias, and more',
    icon: icons.AIInfluencers,
    color: '#4ECDC4',
    callBtnName: 'Explore →',
    link: '/ai-influencers',
    onClick: () => {
    },
  },
  {
    title: 'AI Shorts (Coming Soon)',
    description: 'Transform photos into engaging video content',
    icon: icons.AIShorts,
    color: '#FFD93D',
    callBtnName: 'Join Waitlist →',
    link: '/',
    onClick: () => {
    },
  },
];

const PackSkeleton = ({ darkMode }) => (
  <Card
    sx={{
      backgroundColor: darkMode ? '#1a1a1a' : 'white',
      // border: darkMode ? '1px solid #333' : 'none',
      borderRadius: 2,
      overflow: 'hidden', // Ensure shimmer stays within bounds
      position: 'relative', // For shimmer positioning
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: `linear-gradient(
          90deg,
          transparent 0%,
          ${darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(255, 255, 255, 0.5)'} 50%,
          transparent 100%
        )`,
        animation: 'shimmer 1.5s infinite',
        transform: 'translateX(-100%)',
      },
      '@keyframes shimmer': {
        '100%': {
          transform: 'translateX(100%)',
        },
      },
    }}
  >
    <Skeleton
      variant="rectangular"
      height={165}
      animation="wave"
      sx={{
        backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.06)',
        transform: 'scale(1)', // Prevents weird animation artifacts
      }}
    />
    <CardContent>
      <Skeleton
        variant="text"
        width="60%"
        height={24}
        animation="wave"
        sx={{
          backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.06)',
          transform: 'scale(1)',
          marginBottom: 1,
        }}
      />
      <Skeleton
        variant="text"
        width="90%"
        height={20}
        animation="wave"
        sx={{
          backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.06)',
          transform: 'scale(1)',
        }}
      />
    </CardContent>
  </Card>
);

const PackItem = ({ item, onClick, darkMode }) => (
  <Card
    onClick={onClick}
    sx={{
      borderRadius: 2,
      cursor: 'pointer',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      transition: 'transform 0.2s, box-shadow 0.2s',
      backgroundColor: darkMode ? '#1a1a1a' : 'white',
      // border: darkMode ? '1px solid #333' : 'none', // Added border for dark mode
      '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: darkMode ? '0 4px 20px rgba(0,0,0,0.5)' : 6, // Custom shadow for dark mode
      },
    }}
  >
    <div>
      <div className="relative aspect-w-16 aspect-h-9">
        <img src={item.image} alt={item.title} className="w-full h-full object-cover" />
        {item.isHot && (
          <div
            className="absolute top-2 right-2 bg-pink-500 text-white text-xs font-bold px-2 py-1 rounded-full"
            style={{ marginTop: '-12px' }}
          >
            Hot
          </div>
        )}
      </div>
      <CardContent>
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          gutterBottom
          fontFamily="inherit"
          sx={{ color: darkMode ? '#ffffff' : '#000000' }}
        >
          {item.title}
        </Typography>
        <Typography
          fontFamily="inherit"
          variant="body1"
          sx={{
            color: darkMode ? '#9ca3af' : '#6b7280',
            fontSize: '12px',
          }}
        >
          {item.description}
        </Typography>
      </CardContent>
    </div>
  </Card>
);

const LandingPage = ({ userData, engine }) => {
  const [currentLanguage, setCurrentLanguage] = useState('');
  const [imageIndex, setImageIndex] = useState(0);
  const [isShowLoginWithGoogle, setIsShowLoginWithGoogle] = useState(false);
  const [isShowLoginWithEmailLink, setIsShowLoginWithEmailLink] = useState(false); // For unsupported browsers
  const [redirectPaymentUrl, setRedirectPaymentUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [darkMode, setDarkMode] = useState(true);
  const [showPhotoPipeLine, setShowPhotoPipeLine] = useState(false);
  const [selectedPhotoPack, setSelectedPhotoPack] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowAIPhotoPipeLine, setIsShowAIPhotoPipeLine] = useState(true);

  const urls = [
    'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2Fnew-landing-3.webp?alt=media&token=8536dcff-f6cb-4d53-b9c6-58eedd4d5772',
    'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2Fnew-landing-fashion.webp?alt=media&token=4fcae430-795f-4c8c-b7a1-c489aae2241d',
    'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2Fai-makeup.webp?alt=media&token=4270a0ed-4557-4f70-92b4-320f5c3e67dd',
    'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2Fnew-landing-tech.webp?alt=media&token=40f6ee35-bec9-4608-b961-2e015ad6dbed',
    'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2Ftraveling.webp?alt=media&token=a18b24fe-0ca4-4f51-9f1f-ad889fa04476',
    'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2Fnew-landing-thumbnail.webp?alt=media&token=873e402e-95da-4032-a2c2-3e92df5c9e6e',
  ];

  const exampleImages = [
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-qxa2wahnmdrm00cjcsjaze7w74_0.jpg?generation=1728294730574490&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-w2g8jj70adrm00cjcszbnf3htw_1.jpg?generation=1728296474732532&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-pyejnyrg7hrm00cjctgam6w5pr_0.jpg?generation=1728298688259903&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-09ddcdadahrj60cjctr86dwtg8.jpg?generation=1728299705253085&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-h3j6s2wpg1rm40cjd78a2m3sp4_3.jpg?generation=1728352207836995&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-eaqg2dm3nnrm40cjct1st5jsdc_0.jpg?generation=1728296791938242&alt=media',

    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-530c1t11zdrm60cjh9m91dz390_0.jpg?generation=1728898964111185&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-st53c3f28nrm20cjh3hvrtzw4w_2.jpg?generation=1728873517530471&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-kd70q14551rm20cjfbprptnt2w_2.jpg?generation=1728639270320099&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-64jkr1y6hnrm00cjh8yt77b8j0_1.jpg?generation=1728896187989319&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-mfg4vhqb7srm00cjfbw8rse3wg_3.jpg?generation=1728640017121092&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-jkx2yr552drm20cjfbwr4kax18_2.jpg?generation=1728640059998725&alt=media',

    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-yhf1406vjnrge0ckfmzbgy7atw.jpg?generation=1732973109351099&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-sg63zsx6y5rgc0ckfn5bczf5bc.jpg?generation=1732973877918496&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-8za1gn3tjxrg80ckfn582091pc.jpg?generation=1732973866713641&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-7q2bk7c9r5rgc0ckfn58rgtm50.jpg?generation=1732973871320963&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-qa8qyd43znrge0ckfn5a2a9tr0.jpg?generation=1732973869108185&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-09rwttmchnrga0ckfn59fbj2z4.jpg?generation=1732973873304233&alt=media',

    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-n5fp2cd9gxrm20cjha5v3sv4dc_1.jpg?generation=1728901289265069&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-hr2fer8pzxrm40cjh94rt2h1jw_3.jpg?generation=1728896926422920&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-9fe20d847drm40cjh99sxdkr6r_3.jpg?generation=1728897579123939&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-xbmsxy91r5rm00cjh97arc1kbc_2.jpg?generation=1728897256644317&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-e0e9jm7z0hrm40cjcsk8zztna8_3.jpg?generation=1728294909433701&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-px1ehxf315rm60cjd7yts0xa8r_1.jpg?generation=1728355131870361&alt=media',

    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-1mqxsyd3fnrm00cjd7xt8vd1vw_0.jpg?generation=1728354980776315&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-esjfz4yhtsrm60cjfbvvmhaxs8_0.jpg?generation=1728639940959569&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-gzv4hme1xnrm60cjct2teyjpgc_2.jpg?generation=1728296922784973&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-svprrkgn9hrm20cjfbv8swsg28_0.jpg?generation=1728639842280168&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-ste4b40rknrj40cj5k9a7b82e4_0.jpg?generation=1727328926781706&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-xaq6n9q6yhrj60cj5jtasd02ew_0.jpg?generation=1727327032871385&alt=media',

    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-dk2fbbf1mhrj40cj5jqbthx698_3.jpg?generation=1727326617126822&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-dhae0pczgdrj00cj5jms31s0kg_0.jpg?generation=1727326522674325&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-tg3x5vwt0xrj60cj19p87athd8_3.jpg?generation=1726751892927251&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-hwprbkk0rdrm60cjd89bpd5w98_3.jpg?generation=1728356471190760&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-mhwxj2k7bnrm00cjd868kypjw8_3.jpg?generation=1728356076677359&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-m0baj6fdj5rj60cj1sxbq1pn2g_2.jpg?generation=1726819889792292&alt=media',

    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-vfkhpz4jgdrj00cjnvys489n4r.jpg?generation=1729512729241831&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-85bpw2ap0nrm40cjnvyv32g81r_0.jpg?generation=1729512705066091&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-z6bt3fwzhhrm60cjnw0bhe1024_0.jpg?generation=1729512918892377&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-p8qg97tk31rm40cjnw2aaprg2c_0.jpg?generation=1729513163191641&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-cs67vcr171rj20cjnv1bzmzbf8.jpg?generation=1729508830867517&alt=media',
    'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-gf7t7s58fhrm40cjntnr9m4dg0_0.jpg?generation=1729507351640765&alt=media',
    // 'https://storage.googleapis.com/download/storage/v1/b/reokai.appspot.com/o/files%2Freok-6majr2qvnxrm40cjnv28qgb8gm_3.jpg?generation=1729509045987542&alt=media'
  ];

  const trendingPacks = [
    {
      id: 'business_headshots',
      title: 'Professional headshots',
      description: 'Instantly generate polished professional headshots',
      image:
        'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/admin%2Ffiles%2Fbusiness.png?alt=media&token=61d967e3-a999-4e7f-96b5-370f52566f0b',
      isHot: true,
      category: ['for_business', 'trending'],
      prompt: '',
      createdAt: '2024-09-13T14:03:54.874Z',
      active: true,
      showOnLanding: true,
    },
    {
      id: 'halloween',
      title: '🎄 Christmas photos',
      description: 'Christmas is coming! Transform yourself in Christmas costume',
      image:
        'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/admin%2Ffiles%2Fchristmas.webp?alt=media&token=7b9681cc-4c40-4fc7-9cc6-0b16fd249388',
      isHot: true,
      category: ['for_creator', 'trending'],
      prompt: '',
      createdAt: '2024-09-25T14:32:37.514Z',
      active: true,
      showOnLanding: true,
    },
    {
      id: 'tech_conference',
      title: 'Tech conference',
      description: 'Take polished professional photos at Conference',
      image:
        'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/photoPacks%2Fconference.png?alt=media&token=a85303f1-31a6-479b-bbfb-d1dc2df7507d',
      isHot: false,
      category: ['trending'],
      prompt: '',
      createdAt: '2024-09-13T14:03:54.874Z',
      active: true,
      showOnLanding: true,
    },
    {
      id: 'travel_photos',
      title: 'Travel photos',
      description: 'Imagine any where you go to travel with AI',
      image:
        'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/admin%2Ffiles%2Ftravel.png?alt=media&token=685cc691-8ce8-4aee-9f42-8eab157d8138',
      isHot: false,
      category: ['trending'],
      prompt: '',
      createdAt: '2024-09-13T14:03:54.874Z',
      active: true,
      showOnLanding: true,
    },
    {
      showOnLanding: true,
      description: 'Instant, professional headshots for real estate agents',
      id: 'realestate_headshots',
      createdAt: '2024-10-14T09:25:23.361Z',
      isHot: false,
      image:
        'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/admin%2Ffiles%2Frealestate_headshots.webp?alt=media&token=a939bc21-dc52-461b-bd96-108b05d481f6',
      active: true,
      prompt: '',
      category: ['for_business'],
      title: 'Real estate headshots',
    },
    // {
    //   showOnLanding: true,
    //   title: 'Doctor headshots',
    //   id: 'doctor_headshots',
    //   image:
    //     'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/admin%2Ffiles%2Fdoctor_headshots.webp?alt=media&token=18d87a4b-6166-40e0-9bf6-a9c6af4f6db7',
    //   isHot: false,
    //   createdAt: '2024-10-14T09:54:13.544Z',
    //   active: true,
    //   prompt: '',
    //   description: 'Professional doctor headshots, ready instantly',
    //   category: ['for_business'],
    // },
    {
      image:
        'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/admin%2Ffiles%2Fcardealership_headshots.webp?alt=media&token=29fff675-cdce-4739-bfad-fc39db383756',
      id: 'cardealership_headshots',
      active: true,
      title: 'Car dealership headshots',
      description: 'Drive Sales with AI-enhanced Car dealership headshots',
      prompt: '',
      showOnLanding: true,
      isHot: false,
      createdAt: '2024-10-14T10:23:55.812Z',
      category: ['for_business'],
    },
    {
      active: true,
      isHot: false,
      id: 'healthcare_headshots',
      description: 'Get professional healthcare headshots instantly',
      title: 'Healthcare headshots',
      category: ['for_business'],
      prompt: '',
      title: 'Healthcare headshots',
      description: 'Get professional healthcare headshots instantly',
      showOnLanding: true,
      createdAt: '2024-10-14T04:30:59.749Z',
      image:
        'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/admin%2Ffiles%2Fhealthcare.webp?alt=media&token=c83f2bd9-5218-4777-a18a-b5bbab890d26',
    },
    {
      id: 'teacher_headshots',
      isHot: false,
      title: 'Teacher headshots',
      description: 'Get polished teacher headshots instantly',
      showOnLanding: true,
      category: ['for_business'],
      prompt: '',
      image:
        'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/admin%2Ffiles%2Fteacher_headshots.webp?alt=media&token=89274dfb-5f49-47e0-822f-91fd28b73d41',
      createdAt: '2024-10-14T08:43:55.148Z',
      active: true,
    },
  ];

  const { t } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  // const userPreferredLanguage = navigator.language || navigator.userLanguage;

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const refParam = urlSearchParams.get('ref');
    const affParam = urlSearchParams.get('aff');

    if (refParam) {
      localStorage.setItem('refCode', refParam);
    } else {
      localStorage.setItem('refCode', '');
    }

    if (affParam) {
      localStorage.setItem('affCode', affParam);
    } else {
      localStorage.setItem('affCode', 'reok');
    }

    const redirect = urlSearchParams.get('r');

    if (redirect) {
      localStorage.setItem('redirect', redirect);
    } else {
      localStorage.setItem('redirect', '');
    }

    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setImageIndex(prevIndex => (prevIndex + 1) % urls.length);
    }, 2000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isShowLoginWithGoogle && (userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)))
        setShowModal(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, [isShowLoginWithGoogle, userData]);

  useEffect(() => {
    // Simulate loading
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  function showLogin() {
    setIsShowLoginWithGoogle(true);
  }

  function showLoginWithOutPayment() {
    setRedirectPaymentUrl('');

    // const redirect = localStorage.getItem('redirect');
    // if (redirect === "fb") {
    //   setIsShowLoginWithEmailLink(true);
    //   return;
    // }
    setIsShowLoginWithGoogle(true);
  }

  function showLoginForHeadshots() {
    setRedirectPaymentUrl('/app/?pack=ai_headshots&view=start');
    setIsShowLoginWithGoogle(true);
  }

  function onShowLogin(isShow) {
    setIsShowLoginWithGoogle(false);
    setIsShowLoginWithEmailLink(false);
  }

  function showLoginForPayment(redirectUrl) {
    setRedirectPaymentUrl(redirectUrl);

    const redirect = localStorage.getItem('redirect');
    if (redirect === 'fb') {
      setIsShowLoginWithEmailLink(true);
      return;
    }
    setIsShowLoginWithGoogle(true);
  }

  const handleSubmitted = submitted => {
    setIsSubmitted(submitted);
  };

  const openPhotoPipeLine = (e, item) => {
    e.preventDefault();

    if (userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)) {
      // setSelectedPhotoPack(item);
      // setShowPhotoPipeLine(true);
      setIsShowLoginWithGoogle(true);
    } else {
      window.location.href = `${currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`}/?pack=${item.id}`;
    }
  };

  const closePhotoPipeLine = () => {
    setShowPhotoPipeLine(false);

    if (userData === null || userData === undefined) {
      setIsShowLoginWithGoogle(true);
    }
  };

  const handleSelectCollection = () => {

  }

  const onCloseAIPhotoPipeLine = () => {
    setIsShowAIPhotoPipeLine(false);
  }

  // const onLoginFirst = () =>  {
  //   setTimeout(() => {
  //     setIsShowLoginWithGoogle(true);
  //     setShowPhotoPipeLine(false);
  //   }, 1000);
  // }

  return (
    <div>
      <OpenIconSpeedDial />
      {/* <AIPhotographyLanding
        t={t}
        userData={userData}
        open={isShowAIPhotoPipeLine}
        onClose={onCloseAIPhotoPipeLine}
        darkMode={darkMode}
        handleSelectCollection={handleSelectCollection}
      /> */}
      <main className="bg-black">
        {/* <img src="/images/REOKSTUDIO.webp" alt="reok" className="absolute landing-photo" /> */}
        <div
          style={{
            backgroundImage: "url('/images/REOKSTUDIO.webp')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
          }}
        >
          <div className="landing-cover-header" data-controller="gradient-animation">
            <div className="flex flex-wrap justify-content-evenly lg:w-1/2">
              <div className="lg:w-1/2 px-2">
                <div className="landing-order-first flex items-center">
                  {/* Content for the first column */}
                  <div className="left-landing-wp">
                    <div className="title-landing-wrapper">
                      <div className="text-root-0-2-141 text-landing-title banner-landing-title banner-landing-block-title">
                        <span>
                          <h1 className="display-title-landing fw-bold text-white">{t('landing.title')}</h1>
                        </span>
                      </div>
                      <div className="sub-title-landing" style={{ maxWidth: '490px' }}>
                        <span style={{ color: '#adb5bd' }}>{t('landing.subTitle')}</span>
                      </div>
                      <div className="quick-sign-up-mobile mt-5">
                        <div className="upgrade-btn-wrapper">
                          {userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading) ? (
                            <div onClick={showLoginWithOutPayment} className="upgrade-button">
                              {t('button.try')}
                              <span style={{ marginLeft: '4px' }}>→</span>
                            </div>
                          ) : (
                            <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                              {t('button.try')}
                              <span style={{ marginLeft: '4px' }}>→</span>
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className="customer-demo-wrapper">
                        <div className=" relative mx-auto mt-6 space-y-4">
                          <div className="flex items-center gap-3">
                            <div className="flex -space-x-3 overflow-hidden">
                              <img
                                src="/images/customer13.webp"
                                alt=""
                                className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                                width={40}
                                height={40}
                              />{' '}
                              <img
                                style={{ marginLeft: '-12px' }}
                                src="/images/customer16.webp"
                                alt=""
                                className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                                width={40}
                                height={40}
                              />{' '}
                              <img
                                style={{ marginLeft: '-12px' }}
                                src="/images/customer17.webp"
                                alt=""
                                className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                                width={40}
                                height={40}
                              />{' '}
                              <img
                                style={{ marginLeft: '-12px' }}
                                src="/images/customer11.webp"
                                alt=""
                                className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                                width={40}
                                height={40}
                              />{' '}
                              <img
                                style={{ marginLeft: '-12px' }}
                                src="/images/customer19.webp"
                                alt=""
                                className="inline-block h-10 w-10 rounded-full border-[2px] border-white"
                                width={40}
                                height={40}
                              />
                            </div>{' '}
                            <div className="flex" style={{ color: '#facc15' }}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>{' '}
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>{' '}
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>{' '}
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>{' '}
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                            </div>
                          </div>{' '}
                          <p className="text-sm text-gray-500">{t('landing.loveBy')}</p>
                        </div>
                      </div>
                      <div className="banner-block-ctaHolder-d126-0-2-398  mt-4">
                        <div className="upgrade-btn-wrapper">
                          {userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading) ? (
                            <div onClick={showLoginWithOutPayment} className="upgrade-button">
                              {t('button.try')}
                              <span style={{ marginLeft: '4px' }}>→</span>
                            </div>
                          ) : (
                            <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                              {t('button.try')}
                              <span style={{ marginLeft: '4px' }}>→</span>
                            </Link>
                          )}
                        </div>
                        <span className="text-gray-500">OR</span>
                        <div className="upgrade-btn-wrapper">
                          <a className="enterprise-button" href="/headshots-for-company" target="_">
                            {t('button.forEnterprise')}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-1/2 px-2 mt-4">
                <div className="landing-order-second">
                  <div className="banner-block-mediaWrapper">
                    <div className="before-after-landing-wrapper">
                      <div className="video-slider">
                        <div className="banner-video-landing flex flex-col items-center">
                          <img loading="lazy" className="flex justify-center" alt="reok" width={367} height={313} src={urls[imageIndex]} />
                          {/* <BeforeAfterGallery images={urls}/> */}
                          <div className="flex justify-center mt-2">
                            <div className="w-full flex justify-center">
                              <div className="text-align-center text-sm text-gray-500 pl-5 pr-5 hide-sub-title">
                                {t('landing.subTitle1')}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center text-center relative as-seen-on-wrapper mt-3">
              <span className="text-gray-500">as seen on</span>
              <img className="tiktok-seen-on" loading="lazy" alt="reok" src="images/tiktok.webp" />
              <img className="facebook-seen-on" loading="lazy" alt="reok" src="images/facebook.svg" />
              <img className="instagram-seen-on" loading="lazy" alt="reok" src="images/instagram.webp" />
              <img className="linkedin-seen-on" loading="lazy" alt="reok" src="images/linkedin.png" />
              <img className="x-seen-on" loading="lazy" alt="reok" src="images/x.webp" />
              <img className="reddit-seen-on" loading="lazy" alt="reok" src="images/reddit.webp" />
            </div>
            {/* <div className="flex items-center justify-center text-center relative">
            <MoneyBackGuarantee/>
          </div> */}
          </div>
        </div>
      </main>
      <main>
        {/* Before After slider */}
        <section className="bg-black relative overflow-hidden pt-5">
          {/* <BeforeAfterTestimonial /> */}
          <Box
            sx={{
              width: '100%',
              height: 280,
              display: 'flex',
              position: 'relative',
              backgroundColor: '#000',
              overflow: 'hidden',
              willChange: 'transform',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                animation: 'slideImages 80s linear infinite',
                willChange: 'transform',
                transform: 'translate3d(0, 0, 0)',
                backfaceVisibility: 'hidden',
                '@keyframes slideImages': {
                  '0%': {
                    transform: `translate3d(calc(-280px * ${exampleImages.length}), 0, 0)`,
                  },
                  '100%': {
                    transform: 'translate3d(0, 0, 0)',
                  },
                  '0%': {
                    transform: `translate3d(calc(-280px * ${exampleImages.length}), 0, 0)`,
                  },
                },
                '&:hover': {
                  animationPlayState: 'paused',
                },
              }}
            >
              {[...exampleImages, ...exampleImages, ...exampleImages].map((image, index) => (
                <Box
                  key={index}
                  sx={{
                    position: 'relative',
                    width: '180px',
                    // height: '280px',
                    flexShrink: 0,
                  }}
                >
                  {/* <Skeleton
                    variant="rectangular"
                    width={280}
                    height={280}
                    animation="wave"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      bgcolor: darkMode ? 'grey.900' : 'grey.200',
                      display: 'block',
                    }}
                  /> */}
                  <Box
                    component="img"
                    src={image}
                    loading="lazy"
                    decoding="async"
                    // fetchPriority={index < exampleImages.length ? "high" : "low"}
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      willChange: 'transform',
                      transform: 'translate3d(0, 0, 0)',
                      WebkitFontSmoothing: 'antialiased',
                      transition: 'transform 0.3s ease',
                      opacity: 0,
                      borderRadius: '10px',
                      animation: 'fadeIn 0.5s ease forwards',
                      '@keyframes fadeIn': {
                        to: { opacity: 1 }
                      },
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                    }}
                    // onLoad={(e) => {
                    //   e.target.style.opacity = '1';
                    //   const skeleton = e.target.previousSibling;
                    //   if (skeleton) {
                    //     skeleton.style.display = 'none';
                    //   }
                    // }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </section>

        {/* Why choose us */}
        <section className="bg-black relative overflow-hidden section-top-180">
          <div className="space-y-8 md:space-y-[85px]">
            <div className="container">
              <div className="w-full" id="why-choose-us">
                <div className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 label-wrapper">
                  <span>Save Time & Money</span>
                </div>
                <h2 className="mx-auto relative z-[10] t-50 text-white mb-3 md:mb-6 font-bold text-center mx-auto md:text-center">
                  {/* {t('landing.howItWorks')} */}
                  Your expert AI photographer
                </h2>
              </div>
              <div
                className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-8 md:mb-6"
                style={{ maxWidth: '722px' }}
              >
                <p className="text-lg">
                  Generate high-quality photos instantly, redesign and edit your photos effortlessly with our fully equipped AI studio in seconds!
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-5 mt-5">
                {aiTools.map((tool, index) => (
                  <a href={currentLanguage === 'en' ? tool.link : `/${currentLanguage}${tool.link}`}>
                    <StyledCard
                      key={index}
                    darkMode={darkMode}
                    onClick={tool.onClick}
                    sx={{
                      position: 'relative',
                      overflow: 'hidden',
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '4px',
                        background: 'linear-gradient(115deg, #4fcf70, #fad648, #a767e5, #12bcfe, #44ce7b);',
                        opacity: 0,
                        transition: 'opacity 0.3s ease',
                      },
                      '&:hover::before': {
                        opacity: 1,
                      },
                    }}
                  >
                    <CardContent sx={{ padding: '20px' }}>
                      <div className="flex items-center space-x-5">
                        <div
                          style={{
                            background: `linear-gradient(135deg, ${tool.color}, ${tool.color}CC)`,
                            padding: '16px',
                            borderRadius: '16px',
                            color: 'white',
                            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                            transition: 'transform 0.3s ease',
                            marginRight: '16px',
                          }}
                        >
                          {tool.icon}
                        </div>
                        <div className="flex-1">
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{
                              color: darkMode ? 'white' : 'black',
                              fontWeight: '700',
                              fontSize: '16px',
                              marginBottom: '4px',
                              letterSpacing: '-0.01em',
                            }}
                          >
                            {tool.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: darkMode ? '#888888' : '#666666',
                              fontSize: '0.9rem',
                              lineHeight: 1.5,
                            }}
                          >
                            {tool.description}
                          </Typography>
                        </div>
                      </div>
                      {/* <div className="mt-4 flex justify-end">
                        <button 
                          onClick={tool.onClick}
                          className="px-4 py-2 text-sm font-medium text-gray-400 rounded-lg border border-gray-700 hover:bg-pink-500 hover:border-pink-500 hover:text-white transition-all duration-200"
                        >
                          {tool.callBtnName}
                        </button>
                      </div> */}
                    </CardContent>
                  </StyledCard>
                  </a>
                ))}
              </div>
              {/* <LandingPerfectHeadshot /> */}
            </div>
          </div>
        </section>

        <section className="bg-black relative overflow-hidden pb-1">
          <div className={`container ${darkMode ? 'text-white' : 'text-black'}`}>
            <p className={`label-wrapper mb-3 bold coupon-card`}>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#facc14"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-flame"
                >
                  <path d="M8.5 14.5A2.5 2.5 0 0 0 11 12c0-1.38-.5-2-1-3-1.072-2.143-.224-4.054 2-6 .5 2.5 2 4.9 4 6.5 2 1.6 3 3.5 3 5.5a7 7 0 1 1-14 0c0-1.153.433-2.294 1-3a2.5 2.5 0 0 0 2.5 2.5z" />
                </svg>
                Trending now
              </span>
            </p>
            <Box sx={{ pt: 1 }}>
              <Grid container spacing={2}>
                {isLoading
                  ? Array.from(new Array(8)).map((_, index) => (
                    <Grid item xs={6} sm={6} md={3} lg={3} key={index}>
                      <PackSkeleton darkMode={darkMode} />
                    </Grid>
                  ))
                  : trendingPacks.map((item, key) => (
                    <Grid item xs={6} sm={6} md={3} lg={3} key={key}>
                      <PackItem item={item} onClick={e => openPhotoPipeLine(e, item)} darkMode={darkMode} />
                    </Grid>
                  ))}
              </Grid>
            </Box>
            {/* <div className="rnftg-grid rnftg-gap-4 rnftg-grid-flow-row rnftg-grid-cols-2 md:rnftg-grid-cols-4 lg:rnftg-grid-cols-4 xl:rnftg-grid-cols-4">
              {trendingPacks.map((item, key) => (
                <div
                  key={key}
                  onClick={e => openPhotoPipeLine(e, item)}
                  className={`rounded-lg shadow-md overflow-hidden transition-colors duration-200 w-full ${darkMode ? 'dark-bg-home' : ''
                    } home-item`}
                >
                  <div className="relative aspect-w-16 aspect-h-9">
                    <img src={item.image} alt={item.title} className="w-full h-full object-cover" />
                    {item.isHot && (
                      <div
                        className="absolute top-2 right-2 bg-pink-500 text-white text-xs font-bold px-2 py-1 rounded-full"
                        style={{ marginTop: '-12px' }}
                      >
                        Hot
                      </div>
                    )}
                  </div>
                  <div className="blur-photo-pipe-line">
                    <p className="text-white text-sm px-4 text-center">
                      Start with <br /> 3 easy steps →
                    </p>
                  </div>
                  <div className="p-4">
                    <h3 className="text-sm font-bold mb-1">{item.title}</h3>
                    <p className="font-size-sm text-gray-600 dark:text-gray-300">{item.description}</p>
                  </div>
                </div>
              ))}
            </div> */}
          </div>
        </section>

        {/* How it works */}
        <section className="bg-black relative overflow-hidden section-top-180">
          <div className="space-y-8 md:space-y-[85px]">
            <div className="container">
              <div className="w-full" id="how-it-works">
                <div className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 label-wrapper">
                  <span>3 simple steps</span>
                </div>
                <h2 className="mx-auto relative z-[10] t-50 text-white mb-3 md:mb-6 font-bold text-center mx-auto md:text-center">
                  {t('landing.howItWorks')}
                </h2>
              </div>
              <div
                className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-6 md:mb-6"
                style={{ maxWidth: '722px' }}
              >
                <p className="text-lg">Get your perfect photos or concepts in 3 simple steps</p>
              </div>
              {/* How it works steps */}
              <div className="space-y-8">
                <ul className="cards">
                  <li className="cards_item">
                    <div className="card">
                      <div className="card_content" style={{ maxWidth: '350px' }}>
                        <div className="w-full h-20 flex items-center justify-center">
                          <div className="relative items-center justify-center inline-block p-4 px-5 py-3 overflow-hidden font-medium text-indigo-600 rounded-lg shadow-2xl group">
                            <span className="absolute top-0 left-0 w-40 h-40 -mt-10 -ml-3 transition-all duration-700 bg-red-500 rounded-full blur-md ease"></span>
                            <span className="absolute inset-0 w-full h-full transition duration-700 group-hover:rotate-180 ease">
                              <span className="absolute bottom-0 left-0 w-24 h-24 -ml-10 bg-pink-500 rounded-full blur-md"></span>
                              <span className="absolute bottom-0 right-0 w-24 h-24 -mr-10 bg-pink-500 rounded-full blur-md"></span>
                            </span>
                            <span className="relative text-white t-30">1</span>
                          </div>
                        </div>
                        <h2 className="card_title text-align-center mt-4">{t('landing.step1')}</h2>
                        <p className="card_text text-align-center text-gray-500">{t('landing.step1Sub')}</p>
                      </div>
                    </div>
                  </li>
                  <li className="cards_item">
                    <div className="card">
                      <div className="card_content" style={{ maxWidth: '350px' }}>
                        <div className="w-full h-20 flex items-center justify-center">
                          <div className="relative items-center justify-center inline-block p-4 px-5 py-3 overflow-hidden font-medium text-indigo-600 rounded-lg shadow-2xl group">
                            <span className="absolute top-0 left-0 w-40 h-40 -mt-10 -ml-3 transition-all duration-700 bg-gray-800 rounded-full blur-md ease"></span>
                            <span className="absolute inset-0 w-full h-full transition duration-700 group-hover:rotate-180 ease">
                              <span className="absolute bottom-0 left-0 w-24 h-24 -ml-10 bg-gray-500 rounded-full blur-md"></span>
                              <span className="absolute bottom-0 right-0 w-24 h-24 -mr-10 bg-black-500 rounded-full blur-md"></span>
                            </span>
                            <span className="relative text-white t-30">2</span>
                          </div>
                        </div>
                        <h2 className="card_title text-align-center mt-4">{t('landing.step2')}</h2>
                        <p className="card_text text-align-center text-gray-500">{t('landing.step2Sub')}</p>
                      </div>
                    </div>
                  </li>
                  <li className="cards_item">
                    <div className="card">
                      <div className="card_content" style={{ maxWidth: '350px' }}>
                        <div className="w-full h-20 flex items-center justify-center">
                          <div className="relative items-center justify-center inline-block p-4 px-5 py-3 overflow-hidden font-medium text-indigo-600 rounded-lg shadow-2xl group">
                            <span className="absolute top-0 left-0 w-40 h-40 -mt-10 -ml-3 transition-all duration-700 bg-yellow-500 rounded-full blur-md ease"></span>
                            <span className="absolute inset-0 w-full h-full transition duration-700 group-hover:rotate-180 ease">
                              <span className="absolute bottom-0 left-0 w-24 h-24 -ml-10 bg-yellow rounded-full blur-md"></span>
                              <span className="absolute bottom-0 right-0 w-24 h-24 -mr-10 bg-yellow-500 rounded-full blur-md"></span>
                            </span>
                            <span className="relative text-white t-30">3</span>
                          </div>
                        </div>
                        <h2 className="card_title text-align-center mt-4">{t('landing.step3')}</h2>
                        <p className="card_text text-align-center text-gray-500">{t('landing.step3Sub')}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-black relative overflow-hidden ">
          <div className="space-y-8 md:space-y-[85px]">
            <div className="container">
              <div className="container rnftg-h-full rnftg-w-full mt-4">
                <div className="rnftg-h-full dark:rnftg-bg-gray-900">
                  <div className="rnftg-flex rnftg-flex-col pd-2">
                    <div className="rnftg-grid rnftg-gap-custom rnftg-grid-flow-row rnftg-grid-cols-3 md:rnftg-grid-cols-3 lg:rnftg-grid-cols-6 xl:rnftg-grid-cols-6">
                      {exampleImages.map((url, index) => (
                        <article
                          key={index}
                          className="rnftg-item rnftg-rounded-2xl dark:rnftg-bg-gray-800 rnftg-shadow-lg hover:rnftg-shadow-xl rnftg-transition rnftg-duration-300"
                        >
                          <div className="rnftg-item__img-wrapper_landing">
                            <div className="landing-result">
                              <img className="banner-video" alt="reok" src={url}></img>
                            </div>
                          </div>
                        </article>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Redesign with any Style */}
        <section className="relative overflow-hidden bg-black section-top-180 section-bottom-90" data-layouts="text_card,featured_cards">
          <div className="container">
            <div className="flex flex-wrap justify-content-evenly w-1/2 md:w-full">
              <div className="card-w">
                <div className="w-full p-2">
                  <div className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 label-wrapper">
                    <span>{t('landing.upscaleShowCase.label')}</span>
                  </div>
                  <h2
                    className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-3 md:mb-6 font-bold text-center mx-auto md:text-center"
                    style={{ maxWidth: '722px' }}
                  >
                    {t('landing.upscaleShowCase.title')}
                  </h2>
                  <div
                    className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-6 md:mb-6"
                    style={{ maxWidth: '722px' }}
                  >
                    <p className="text-lg">{t('landing.upscaleShowCase.subTitle')}</p>
                  </div>
                  <div className="flex flex-col align-items-center mb-6">
                    <div className="getstarted-btn-wrapper">
                      {userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading) ? (
                        <div onClick={showLoginWithOutPayment} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>→</span>
                        </div>
                      ) : (
                        <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>→</span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-w">
                <div className="flex justify-center">
                  <BeforeAfter
                    key={1}
                    images={{
                      before:
                        'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2Fupscale-before.webp?alt=media&token=c54e73f1-d492-4096-8713-ada61ef22d0d',
                      after:
                        'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2Fupscale-after.webp?alt=media&token=481cfabb-bbe6-4f0a-8f72-85c3a92567ba',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Creative redesign for exteriors */}
        {/* <section
          className="relative overflow-hidden bg-black section-top-90 section-bottom-90"
          data-layouts="text_card,featured_cards"
        >
          <div className="container">
            <div className="flex flex-wrap justify-content-evenly w-1/2 md:w-full">
              <div className="card-w landing-order-second">
                <div className="flex justify-center">
                  <BeforeAfter
                    key={2}
                    images={{
                      before:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F3.webp?alt=media&token=bca96e92-1d8f-47b5-b74f-e41fbfbb044d',
                      after:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F4.webp?alt=media&token=5ee936a2-291d-44c9-9fcc-bb2f45be1283',
                    }}
                  />
                </div>
              </div>
              <div className="card-w landing-order-first">
                <div className="w-full p-2">
                  <div className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 label-wrapper">
                    <span>{t('landing.exteriorShowCase.label')}</span>
                  </div>
                  <h2
                    className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-3 md:mb-6 font-bold text-center mx-auto md:text-center"
                    style={{ maxWidth: '722px' }}
                  >
                    {t('landing.exteriorShowCase.title')}
                  </h2>
                  <div
                    className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-6 md:mb-6"
                    style={{ maxWidth: '722px' }}
                  >
                    <p className="text-lg">
                      {t('landing.exteriorShowCase.subTitle')}
                    </p>
                  </div>
                  <div className="flex flex-col align-items-center mb-6">
                    <div className="getstarted-btn-wrapper">
                      {(userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)) ? (

                        <div onClick={showLoginWithOutPayment} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>→</span>
                        </div>
                      ) : (
                        <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>→</span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* Fill an empty room with furnitures */}
        {/* <section
          className="relative overflow-hidden bg-black section-top-90 section-bottom-90"
          data-layouts="text_card,featured_cards"
        >
          <div className="container">
            <div className="flex flex-wrap justify-content-evenly w-1/2 md:w-full">
              <div className="card-w">
                <div className="w-full p-2">
                  <div className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 label-wrapper">
                    <span>{t('landing.furnitureShowCase.label')}</span>
                  </div>
                  <h2
                    className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-3 md:mb-6 font-bold text-center mx-auto md:text-center"
                    style={{ maxWidth: '722px' }}
                  >
                    {t('landing.furnitureShowCase.title')}
                  </h2>
                  <div
                    className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-6 md:mb-6"
                    style={{ maxWidth: '722px' }}
                  >
                    <p className="text-lg">
                      {t('landing.furnitureShowCase.subTitle')}
                    </p>
                  </div>
                  <div className="flex flex-col align-items-center mb-6">
                    <div className="getstarted-btn-wrapper">
                      {(userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)) ? (

                        <div onClick={showLoginWithOutPayment} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>→</span>
                        </div>
                      ) : (
                        <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>→</span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-w">
                <div className="flex justify-center">
                  <BeforeAfter
                    key={2}
                    images={{
                      before:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F30.webp?alt=media&token=89fda116-52d0-4917-989c-71b815c7b416',
                      after:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F31.webp?alt=media&token=5c4b57b3-7851-4d2b-a528-47c19d4814a5',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* Remove anything */}
        <section className="relative overflow-hidden bg-black section-top-90 section-bottom-90" data-layouts="text_card,featured_cards">
          <div className="container">
            <div className="flex flex-wrap justify-content-evenly w-1/2 md:w-full">
              <div className="card-w landing-order-second">
                <div className="flex justify-center">
                  <BeforeAfter
                    images={{
                      before:
                        'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2Fclean-before.webp?alt=media&token=a3936b8b-dd93-420e-9f47-f29bd9887173',
                      after:
                        'https://firebasestorage.googleapis.com/v0/b/reokai.appspot.com/o/landing%2Fclean-after.webp?alt=media&token=a7e44c7e-fd65-4a9e-9a6a-b45121eddacf',
                    }}
                  />
                </div>
              </div>
              <div className="card-w landing-order-first">
                <div className="w-full p-2">
                  <div className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 label-wrapper">
                    <span>{t('landing.magicEraserShowCase.label')}</span>
                  </div>
                  <h2
                    className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-3 md:mb-6 font-bold text-center mx-auto md:text-center"
                    style={{ maxWidth: '722px' }}
                  >
                    {t('landing.magicEraserShowCase.title')}
                  </h2>
                  <div
                    className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-6 md:mb-6"
                    style={{ maxWidth: '722px' }}
                  >
                    <p className="text-lg">{t('landing.magicEraserShowCase.subTitle')}</p>
                  </div>
                  <div className="flex flex-col align-items-center mb-6">
                    <div className="getstarted-btn-wrapper">
                      {userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading) ? (
                        <div onClick={showLoginWithOutPayment} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>→</span>
                        </div>
                      ) : (
                        <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>→</span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Upscale any design to 4K */}
        {/* <section
          className="relative overflow-hidden bg-black section-top-90 section-bottom-90"
          data-layouts="text_card,featured_cards"
        >
          <div className="container">
            <div className="flex flex-wrap justify-content-evenly w-1/2 md:w-full">
              <div className="card-w">
                <div className="w-full p-2">
                  <div className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 label-wrapper">
                    <span>{t('landing.upscaleShowCase.label')}</span>
                  </div>
                  <h2
                    className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-3 md:mb-6 font-bold text-center mx-auto md:text-center"
                    style={{ maxWidth: '722px' }}
                  >
                    {t('landing.upscaleShowCase.title')}
                  </h2>
                  <div
                    className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-6 md:mb-6"
                    style={{ maxWidth: '722px' }}
                  >
                    <p className="text-lg">
                      {t('landing.upscaleShowCase.subTitle')}
                    </p>
                  </div>
                  <div className="flex flex-col align-items-center mb-6">
                    <div className="getstarted-btn-wrapper">
                      {(userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)) ? (

                        <div onClick={showLoginWithOutPayment} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>→</span>
                        </div>
                      ) : (
                        <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>→</span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-w">
                <div className="flex justify-center">
                  <BeforeAfter
                    images={{
                      before:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F46.webp?alt=media&token=3239a1df-c14a-402a-80e8-9e78151cba5d',
                      after:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F47.webp?alt=media&token=adba3673-c203-417c-80fe-83bddf933013',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* Custom AI designers creativity */}
        {/* <section
          className="relative overflow-hidden bg-black section-top-90 section-bottom-90"
          data-layouts="text_card,featured_cards"
        >
          <div className="container">
            <div className="flex flex-wrap justify-content-evenly w-1/2 md:w-full">
              <div className="card-w landing-order-second">
                <div className="flex justify-center">
                  <BeforeAfter
                    images={{
                      before:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F21.webp?alt=media&token=e894742f-5809-4c33-9086-1e824fb87cf8',
                      after:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F44.webp?alt=media&token=0a51edd8-52f2-4265-b22f-2d3deefb94a9',
                    }}
                  />
                </div>
              </div>
              <div className="card-w landing-order-first">
                <div className="w-full p-2">
                  <div className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 label-wrapper">
                    <span>{t('landing.creativityShowCase.label')}</span>
                  </div>
                  <h2
                    className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-3 md:mb-6 font-bold text-center mx-auto md:text-center"
                    style={{ maxWidth: '722px' }}
                  >
                    {t('landing.creativityShowCase.title')}
                  </h2>
                  <div
                    className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-6 md:mb-6"
                    style={{ maxWidth: '722px' }}
                  >
                    <p className="text-lg">
                      {t('landing.creativityShowCase.subTitle')}
                    </p>
                  </div>
                  <div className="flex flex-col align-items-center mb-6">
                    <div className="getstarted-btn-wrapper">
                      {(userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)) ? (

                        <div onClick={showLoginWithOutPayment} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>→</span>
                        </div>
                      ) : (
                        <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>→</span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* Sketch to realistic designs */}
        {/* <section
          className="relative overflow-hidden bg-black section-top-90 section-bottom-90"
          data-layouts="text_card,featured_cards"
        >
          <div className="container">
            <div className="flex flex-wrap justify-content-evenly w-1/2 md:w-full">
              <div className="card-w">
                <div className="w-full p-2">
                  <div className="mx-auto relative z-[10] text-center mx-auto md:text-center mb-3 label-wrapper">
                    <span>{t('landing.sketchShowCase.label')}</span>
                  </div>
                  <h2
                    className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-3 md:mb-6 font-bold text-center mx-auto md:text-center"
                    style={{ maxWidth: '722px' }}
                  >
                    {t('landing.sketchShowCase.title')}
                  </h2>
                  <div
                    className="mx-auto font-body text-gray-500 relative z-10 t-20-l text-center mx-auto md:text-center mb-6 md:mb-6"
                    style={{ maxWidth: '722px' }}
                  >
                    <p className="text-lg">
                      {t('landing.sketchShowCase.subTitle')}
                    </p>
                  </div>
                  <div className="flex flex-col align-items-center mb-6">
                    <div className="getstarted-btn-wrapper">
                      {(userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading)) ? (

                        <div onClick={showLoginWithOutPayment} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>→</span>
                        </div>
                      ) : (
                        <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                          {t('button.try')}
                          <span style={{ marginLeft: '4px' }}>→</span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-w">
                <div className="flex justify-center">
                  <BeforeAfter
                    images={{
                      before:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F5.webp?alt=media&token=3b21a822-754e-4aff-acd8-278b17250ca3',
                      after:
                        'https://firebasestorage.googleapis.com/v0/b/airoomdecor.appspot.com/o/landing-slide%2F48.webp?alt=media&token=f7dae04e-a20c-448f-8608-6d5cf04e0bfb',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* Show customers feedback section */}
        {/* <section
          className="relative overflow-hidden bg-black section-top-none section-top-90 section-bottom-90"
        >
          <Testimonial t={t} />
        </section> */}
        {/* Pricing section */}
        <section className="bg-neutral-black section-top-90 section-bottom-90">
          <PricingLandingPage
            t={t}
            currentLanguage={currentLanguage}
            userData={userData}
            showLoginForPayment={showLoginForPayment}
            showLoginWithOutPayment={showLoginForHeadshots}
          />
        </section>
        {/* FAQ section */}
        <section className="bg-neutral-black section-bottom-90">
          <div className="relative bg-neutral-black mb-4">
            <div className="container">
              <FAQ t={t} />
            </div>
          </div>
        </section>
        <section
          className="bg-[#101014] bg-blend-luminosity bg-no-repeat bg-center bg-cover h-[50vh] flex items-center justify-center"
          style={{
            backgroundImage: "linear-gradient(360deg, rgb(13, 13, 13) 0%, rgba(16, 16, 20, 30%) 100%), url('/images/footer-cover.webp')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="container mx-auto px-4 call-to-action">
            <div className="flex flex-col items-center text-center">
              <h2
                className="mx-auto relative z-[10] text-white t-50 text-neutral-black mb-6 md:mb-6 font-bold text-center mx-auto md:text-center"
                style={{ maxWidth: '722px' }}
              >
                {t('landing.joinNow')}
              </h2>
              <div className="getstarted-btn-wrapper">
                {userData === null || userData === undefined || (userData.loading !== undefined && !userData.loading) ? (
                  <div onClick={showLoginWithOutPayment} className="upgrade-button">
                    {t('button.try')}
                    <span style={{ marginLeft: '4px' }}>→</span>
                  </div>
                ) : (
                  <Link to={currentLanguage === 'en' ? '/app' : `/${currentLanguage}/app`} className="upgrade-button">
                    {t('button.try')}
                    <span style={{ marginLeft: '4px' }}>→</span>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
      {isShowLoginWithGoogle && (
        <QuickSignUpEmailAndGoogleContainer
          onShowLogin={onShowLogin}
          redirectPaymentUrl={redirectPaymentUrl}
          isSubmitted={isSubmitted}
          onSubmitted={handleSubmitted}
        />
      )}
      {/* {isShowLoginWithGoogle && <QuickSignUpLandingPageContainer onShowLogin={onShowLogin} redirectPaymentUrl={redirectPaymentUrl} />} */}
      <CookieConsent />
      <GiveawayModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        isSubmitted={isSubmitted}
        onSubmitted={handleSubmitted}
        currentLanguage={currentLanguage}
        t={t}
      />
      {showPhotoPipeLine && (
        <PhotoPipeLine
          onClose={closePhotoPipeLine}
          firebase={engine}
          darkMode={darkMode}
          userData={userData}
          t={t}
          photoPack={selectedPhotoPack}
        />
      )}
    </div>
  );
};

export default LandingPage;
