import { combineReducers } from 'redux';
import user from './user';
import pricingReducer from '../store/slices/pricingSlice';
import photoStepReducer from '../store/slices/photoStepSlice';

const reducers = combineReducers({
  user,
  pricing: pricingReducer,
  photoStep: photoStepReducer,
});

export default reducers;
