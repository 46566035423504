import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closePricingDialog, setActiveTab } from '../../store/slices/pricingSlice';

import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';

const CHECKOUT_FREE_URL = `${process.env.REACT_APP_PAYMENT_CHECKOUT_URL}/${process.env.REACT_APP_PAYMENT_FREE}`;
const CHECKOUT_PREMIUM_MONTHLY_URL = `${process.env.REACT_APP_PAYMENT_CHECKOUT_URL}/${process.env.REACT_APP_PAYMENT_PREMIUM_MONTHLY}`;
const CHECKOUT_PREMIUM_YEARLY_URL = `${process.env.REACT_APP_PAYMENT_CHECKOUT_URL}/${process.env.REACT_APP_PAYMENT_PREMIUM_YEARLY}`;
const CHECKOUT_BUSINESS_MONTHLY_URL = `${process.env.REACT_APP_PAYMENT_CHECKOUT_URL}/${process.env.REACT_APP_PAYMENT_BUSINESS_MONTHLY}`;
const CHECKOUT_BUSINESS_YEARLY_URL = `${process.env.REACT_APP_PAYMENT_CHECKOUT_URL}/${process.env.REACT_APP_PAYMENT_BUSINESS_YEARLY}`;
const TYPE_HEADSHOTS = 'headshots';

const NewPricing = ({
  // tab,
  userData,
  onShowPricing,
  t,
  startHeadshots,
}) => {
  const dispatch = useDispatch();
  const isPricingOpen = useSelector((state) => state.pricing.isOpen);
  const activeModeTab = useSelector((state) => state.pricing.activeTab);

  const [affCode, setAffCode] = useState('');
  const [darkMode, setDarkMode] = useState(true);

  const yearlyPlans = [
    // {
    //   name: 'Starter',
    //   price: 5,
    //   originalPrice: 0,
    //   credits: '500',
    //   description: t('newPricing.yearlyPlan.starter.description'),
    //   features: [
    //     t('newPricing.yearlyPlan.starter.notAllowedFeature1'),
    //     t('newPricing.yearlyPlan.starter.feature1'),
    //     t('newPricing.yearlyPlan.starter.feature2'),
    //     t('newPricing.yearlyPlan.starter.feature3'),
    //     t('newPricing.yearlyPlan.starter.feature4'),
    //     t('newPricing.yearlyPlan.starter.feature5'),
    //   ],
    //   notAllowedFeatures: [
    //     t('newPricing.yearlyPlan.starter.notAllowedFeature2'),
    //   ],
    //   earlyAccess: false,
    //   discount: 10,
    //   bill: t('newPricing.noBill'),
    //   period: t('newPricing.month'),
    //   isFree: true,
    //   included: t('newPricing.yearlyPlan.starter.included'),
    //   checkout: CHECKOUT_FREE_URL,
    //   type: 'free',
    // },
    {
      name: 'Premium',
      price: 19,
      originalPrice: 39,
      credits: '30,000',
      description: t('newPricing.yearlyPlan.premium.description'),
      features: [
        t('newPricing.yearlyPlan.premium.feature1'),
        t('newPricing.yearlyPlan.premium.feature2'),
        t('newPricing.yearlyPlan.premium.feature3'),
        // t('newPricing.yearlyPlan.premium.feature4'),
        t('newPricing.yearlyPlan.premium.feature5'),
        t('newPricing.yearlyPlan.premium.feature6'),
        t('newPricing.yearlyPlan.premium.feature7'),
      ],
      earlyAccess: true,
      discount: 50,
      highlighted: true,
      bill: t('newPricing.billYearly') + " $228",
      period: t('newPricing.month'),
      isFree: false,
      included: t('newPricing.yearlyPlan.premium.included'),
      checkout: CHECKOUT_PREMIUM_YEARLY_URL,
      type: 'premium_yearly',
    },
    {
      name: 'Business',
      price: 66,
      originalPrice: 132,
      credits: '180,000',
      description: t('newPricing.yearlyPlan.business.description'),
      features: [
        t('newPricing.yearlyPlan.business.feature1'),
        t('newPricing.yearlyPlan.business.feature2'),
        t('newPricing.yearlyPlan.business.feature3'),
        // t('newPricing.yearlyPlan.business.feature4'),
        t('newPricing.yearlyPlan.business.feature5'),
        t('newPricing.yearlyPlan.business.feature6'),
        t('newPricing.yearlyPlan.business.feature7'),
      ],
      earlyAccess: false,
      discount: 50,
      bill: t('newPricing.billYearly') + " $792",
      period: t('newPricing.month'),
      isFree: false,
      included: t('newPricing.yearlyPlan.business.included'),
      checkout: CHECKOUT_BUSINESS_YEARLY_URL,
      type: 'business_yearly',
    },
    {
      name: 'Enterprise',
      price: 0,
      originalPrice: 0,
      credits: 'customize',
      description: "Boost your large-scale business with AI Studio capabilities.",
      features: [
        t('newPricing.yearlyPlan.starter.notAllowedFeature1'),
        t('newPricing.yearlyPlan.starter.feature1'),
        t('newPricing.yearlyPlan.starter.feature2'),
        t('newPricing.yearlyPlan.starter.feature3'),
        t('newPricing.yearlyPlan.starter.feature4'),
        t('newPricing.yearlyPlan.starter.feature5'),
      ],
      notAllowedFeatures: [
        t('newPricing.yearlyPlan.starter.notAllowedFeature2'),
      ],
      earlyAccess: false,
      discount: 10,
      bill: t('newPricing.noBill'),
      period: t('newPricing.month'),
      isFree: true,
      included: t('newPricing.yearlyPlan.starter.included'),
      checkout: CHECKOUT_FREE_URL,
      type: 'enterprise',
    },
  ];

  const monthlyPlans = [
    // {
    //   name: 'Starter',
    //   price: 5,
    //   originalPrice: 0,
    //   credits: '500',
    //   description: t('newPricing.monthlyPlan.starter.description'),
    //   features: [
    //     t('newPricing.monthlyPlan.starter.notAllowedFeature1'),
    //     t('newPricing.monthlyPlan.starter.feature1'),
    //     t('newPricing.monthlyPlan.starter.feature2'),
    //     t('newPricing.monthlyPlan.starter.feature3'),
    //     t('newPricing.monthlyPlan.starter.feature4'),
    //     t('newPricing.monthlyPlan.starter.feature5'),
    //   ],
    //   notAllowedFeatures: [
    //     // t('newPricing.monthlyPlan.starter.notAllowedFeature1'),
    //     t('newPricing.monthlyPlan.starter.notAllowedFeature2'),
    //   ],
    //   earlyAccess: false,
    //   discount: 10,
    //   bill: t('newPricing.noBill'),
    //   period: t('newPricing.month'),
    //   isFree: true,
    //   included: t('newPricing.monthlyPlan.starter.included'),
    //   checkout: CHECKOUT_FREE_URL,
    //   type: 'free',
    // },
    {
      name: 'Premium',
      price: 29,
      originalPrice: 39,
      credits: '2,500',
      description: t('newPricing.monthlyPlan.premium.description'),
      features: [
        t('newPricing.monthlyPlan.premium.feature1'),
        t('newPricing.monthlyPlan.premium.feature2'),
        t('newPricing.monthlyPlan.premium.feature3'),
        // t('newPricing.monthlyPlan.premium.feature4'),
        t('newPricing.monthlyPlan.premium.feature5'),
        t('newPricing.monthlyPlan.premium.feature6'),
        t('newPricing.monthlyPlan.premium.feature7'),
      ],
      earlyAccess: true,
      discount: 15,
      highlighted: true,
      bill: t('newPricing.billMonthly'),
      period: t('newPricing.month'),
      isFree: false,
      included: t('newPricing.monthlyPlan.premium.included'),
      checkout: CHECKOUT_PREMIUM_MONTHLY_URL,
      type: 'premium_monthly',
    },
    {
      name: 'Business',
      price: 99,
      originalPrice: 132,
      credits: '15,000',
      description: t('newPricing.monthlyPlan.business.description'),
      features: [
        t('newPricing.monthlyPlan.business.feature1'),
        t('newPricing.monthlyPlan.business.feature2'),
        t('newPricing.monthlyPlan.business.feature3'),
        // t('newPricing.monthlyPlan.business.feature4'),
        t('newPricing.monthlyPlan.business.feature5'),
        t('newPricing.monthlyPlan.business.feature6'),
        t('newPricing.monthlyPlan.business.feature7'),
      ],
      earlyAccess: false,
      discount: 15,
      bill: t('newPricing.billMonthly'),
      period: t('newPricing.month'),
      isFree: false,
      included: t('newPricing.monthlyPlan.business.included'),
      checkout: CHECKOUT_BUSINESS_MONTHLY_URL,
      type: 'business_monthly',
    },
    {
      name: 'Enterprise',
      price: 0,
      originalPrice: 0,
      credits: 'customize',
      description: "Boost your large-scale business with AI Studio capabilities.",
      features: [
        t('newPricing.yearlyPlan.starter.notAllowedFeature1'),
        t('newPricing.yearlyPlan.starter.feature1'),
        t('newPricing.yearlyPlan.starter.feature2'),
        t('newPricing.yearlyPlan.starter.feature3'),
        t('newPricing.yearlyPlan.starter.feature4'),
        t('newPricing.yearlyPlan.starter.feature5'),
      ],
      notAllowedFeatures: [
        t('newPricing.yearlyPlan.starter.notAllowedFeature2'),
      ],
      earlyAccess: false,
      discount: 10,
      bill: t('newPricing.noBill'),
      period: t('newPricing.month'),
      isFree: true,
      included: t('newPricing.yearlyPlan.starter.included'),
      checkout: CHECKOUT_FREE_URL,
      type: 'enterprise',
    },
  ];

  const headshotPlans = [
    {
      name: 'Basic',
      price: 19,
      originalPrice: 40,
      credits: 'Bonus 100',
      description: t('newPricing.headshotsPlan.basic.description'),
      features: [
        t('newPricing.headshotsPlan.basic.feature1'),
        // t('newPricing.headshotsPlan.basic.feature2'),
        // t('newPricing.headshotsPlan.basic.feature3'),
      ],
      bonus: t('newPricing.headshotsPlan.basic.bonus'),
      bonusFeatures: [
        t('newPricing.headshotsPlan.basic.bonusFeature1'),
        t('newPricing.headshotsPlan.basic.bonusFeature2'),
        t('newPricing.headshotsPlan.basic.bonusFeature3'),
        t('newPricing.headshotsPlan.basic.bonusFeature4'),
      ],
      earlyAccess: true,
      discount: 50,
      highlighted: false,
      bill: t('newPricing.noBill'),
      period: ' person',
      calledBuy: 'Start 40 headshots →',
      isFree: false,
      included: t('newPricing.headshotsPlan.pro.included'),
      checkout: CHECKOUT_PREMIUM_MONTHLY_URL,
      type: TYPE_HEADSHOTS,
    },
    {
      name: 'Pro',
      price: 29,
      originalPrice: 60,
      credits: 'Bonus 200',
      description: t('newPricing.headshotsPlan.pro.description'),
      features: [
        t('newPricing.headshotsPlan.pro.feature1'),
        // t('newPricing.headshotsPlan.pro.feature2'),
        // t('newPricing.headshotsPlan.pro.feature3'),
      ],
      bonus: t('newPricing.headshotsPlan.pro.bonus'),
      bonusFeatures: [
        t('newPricing.headshotsPlan.pro.bonusFeature1'),
        t('newPricing.headshotsPlan.pro.bonusFeature2'),
        t('newPricing.headshotsPlan.pro.bonusFeature3'),
        t('newPricing.headshotsPlan.pro.bonusFeature4'),
      ],
      earlyAccess: true,
      discount: 50,
      highlighted: true,
      bill: t('newPricing.noBill'),
      period: ' person',
      calledBuy: 'Start 100 headshots →',
      isFree: false,
      included: t('newPricing.headshotsPlan.pro.included'),
      checkout: CHECKOUT_PREMIUM_MONTHLY_URL,
      type: TYPE_HEADSHOTS,
    },
    {
      name: 'Executive',
      price: 49,
      originalPrice: 100,
      credits: 'Bonus 500',
      description: t('newPricing.headshotsPlan.executive.description'),
      features: [
        t('newPricing.headshotsPlan.executive.feature1'),
        // t('newPricing.headshotsPlan.executive.feature2'),
        // t('newPricing.headshotsPlan.executive.feature3'),
      ],
      bonus: t('newPricing.headshotsPlan.executive.bonus'),
      bonusFeatures: [
        t('newPricing.headshotsPlan.executive.bonusFeature1'),
        t('newPricing.headshotsPlan.executive.bonusFeature2'),
        t('newPricing.headshotsPlan.executive.bonusFeature3'),
        t('newPricing.headshotsPlan.executive.bonusFeature4'),
      ],
      earlyAccess: false,
      discount: 50,
      bill: t('newPricing.noBill'),
      period: ' person',
      calledBuy: 'Start 200 headshots →',
      isFree: false,
      included: t('newPricing.headshotsPlan.executive.included'),
      checkout: CHECKOUT_BUSINESS_MONTHLY_URL,
      type: TYPE_HEADSHOTS,
    },
  ];

  useEffect(() => {
    // Retrieve the stored referralCode from local storage
    const storedAffCode = localStorage.getItem('affCode');

    if (storedAffCode) {
      setAffCode(storedAffCode)
    }
  }, []);

  // useEffect(() => {
  //   if (tab === 0) {
  //     dispatch(setActiveTab('0'));
  //   } else if (tab === 1) {
  //     dispatch(setActiveTab('1'));
  //   } else {
  //     dispatch(setActiveTab('2'));
  //   }
  // }, [tab, dispatch]);

  const getPlans = () => {
    switch (activeModeTab) {
      case '0':
        return headshotPlans;
      case '1':
        return monthlyPlans;
      case '2':
      default:
        return yearlyPlans;
    }
  };

  const plans = getPlans();

  const handleModeTabChange = (key) => {
    dispatch(setActiveTab(key));
  };
  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={isPricingOpen}
        onClose={() => dispatch(closePricingDialog())}
      >
        <ModalDialog
          sx={{
            width: {
              xs: '100%',
              md: '1200px'
            },
            // height: '100%',
            background: '#09090b',
            border: '1px solid #373737',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <ModalClose
            onClose={() => dispatch(closePricingDialog())}
            sx={{
              position: 'absolute',
              top: '4px',
              right: '4px',
              color: 'white',
              zIndex: 1000,
            }}
          />
          <span className="mt-2 flex flex-col items-center gap-1 text-white">
            <span>
              <span className={activeModeTab === '0' ? 'text-pink-600' : ''}>
                One time
              </span>
              {': For Headshot'}
            </span>
            <span>
              <span className={activeModeTab === '1' ? 'text-pink-600' : ''}>
                Monthly
              </span>
              {' & '}
              <span className={activeModeTab === '2' ? 'text-pink-600' : ''}>
                Yearly
              </span>
              {': For Business'}
            </span>
          </span>
          <div className="md:p-6 text-white">
            <div className="flex justify-center mb-4">
              <div className={`${darkMode ? 'dark-border-full' : 'light-border-full'} rounded-lg`}>
                <div className={`flex bold`}>
                  <button
                    className={`py-3 px-4 text-center transition-colors duration-200 ease-in-out
                    ${activeModeTab === '0'
                        ? darkMode
                          ? 'dark-bg-tab text-white border-b-2 border-pink-500'
                          : 'gray-bg-tab text-black border-b-2 border-pink-500'
                        : darkMode
                          ? 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                          : 'text-gray-600 hover:bg-gray-200'
                      }
                    `}
                    style={{ borderRadius: '4px', fontSize: '14px', margin: '4px' }}
                    onClick={() => handleModeTabChange('0')}
                  >
                    {t('newPricing.headshots')}
                  </button>
                  <button
                    className={`py-3 px-4 text-center transition-colors duration-200 ease-in-out
                    ${activeModeTab === '1'
                        ? darkMode
                          ? 'dark-bg-tab text-white border-b-2 border-pink-500'
                          : 'gray-bg-tab text-black border-b-2 border-pink-500'
                        : darkMode
                          ? 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                          : 'text-gray-600 hover:bg-gray-200'
                      }
                    `}
                    style={{ borderRadius: '4px', fontSize: '14px', margin: '4px' }}
                    onClick={() => handleModeTabChange('1')}
                  >
                    {t('newPricing.monthly')}
                  </button>
                  <button
                    className={`py-2 px-4 text-center transition-colors duration-200 ease-in-out
                    ${activeModeTab === '2'
                        ? darkMode
                          ? 'dark-bg-tab text-white border-b-2'
                          : 'gray-bg-tab text-black border-b-2 border-pink-500'
                        : darkMode
                          ? 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                          : 'text-gray-600 hover:bg-gray-200'
                      }
                  `}
                    style={{ borderRadius: '4px', fontSize: '14px', margin: '4px' }}
                    onClick={() => handleModeTabChange('2')}
                  >
                    {t('newPricing.yearly')}
                    <span
                      className="ml-2 px-1 py-1"
                      style={{
                        backgroundColor: '#44d48326',
                        color: '#44d483',
                        borderRadius: '4px',
                        marginLeft: '4px',
                        fontWeight: '600'
                      }}>
                      -50%
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className='flex justify-center items-center'>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                {plans.map((plan, index) => (
                  <>
                    {plan.type !== "enterprise" && (
                    <div className={`text-white p-6 rounded-xl max-w-sm ${plan.highlighted ? 'border-1 border-white-300' : ''}`} style={{ background: '#1e1e1e' }}>
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-2xl font-bold text-white">{plan.name}</h2>
                        <span
                          className="text-white px-2 py-1 rounded-full text-sm font-semibold"
                          style={{ border: '1px solid #fc5185', background: 'none', cursor: 'pointer', borderRadius: '6px' }}
                        >
                          {plan.credits}
                          <svg
                            style={{ marginTop: '-2px', marginRight: '-2px' }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 32 32"
                            fill="#ffc337"
                          >
                            <path d="M17.333 4v9.333h8L14.666 28v-9.333H6.667z" />
                          </svg>
                        </span>
                      </div>
                      <p className="text-gray-400 mb-6 text-sm">
                        {plan.description}
                      </p>
                      <div className="text-white rounded-lg">
                        <div className="flex items-center gap-2">
                          <span className="text-5xl font-semibold text-white">
                            ${plan.price}
                          </span>
                          <div className="flex flex-col items-start justify-center">
                            {!plan.isFree && (
                              <>
                                <span
                                  className="text-lg font-medium text-primary-200 line-through"
                                  style={{ color: '#ffacc6' }}
                                >
                                  ${plan.originalPrice}
                                </span>
                                <span className="text-sm font-medium text-neutral-400">
                                  /{plan.period}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="flex space-x-2">
                          <span
                            className="px-2 py-1 text-sm font-semibold flex items-center"
                            style={{ background: '#98305626', color: '#c77d94', borderRadius: '6px' }}
                          >
                            {plan.bill}
                          </span>
                          {plan.discount && !plan.isFree &&
                            <span
                              className="px-2 py-1 text-sm font-semibold flex items-center"
                              style={{ background: '#44d48326', color: '#44d483', borderRadius: '6px' }}
                            >
                              <svg width="12" height="12" fill="none" viewBox="0 0 12 12">
                                <path fill="currentColor" d="M8.027 1H4.682a.496.496 0 0 0-.476.337L2.522 6.58c-.098.307.141.617.476.617H5.31l-.764 3.218c-.117.494.544.8.874.404l3.97-4.754c.26-.312.029-.775-.389-.775H6.802l1.68-3.62c.146-.315-.095-.67-.455-.67Z" />
                              </svg>
                              {plan.discount}% off
                            </span>
                          }
                        </div>
                        <hr className='mt-4 mb-4' />
                        <h3 className="text-sm bold mb-4">{plan.included}</h3>
                        <ul className="space-y-2 mb-5">
                          {plan.features.map((feature, index) => (
                            <li key={index} className={`flex items-center text-white text-sm text-gray-400`}>
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                  fill="#72ff9a"
                                />
                              </svg>
                              <span>{feature}</span>
                              {/* <span className={`${!plan.isFree && (index === 0 || index === 1 || index === 2) ? 'text-white' : ''}`}>{feature}</span> */}
                            </li>
                          ))}
                          {plan.notAllowedFeatures && plan.notAllowedFeatures.map((feature, index) => (
                            <li key={index} className={`flex items-center text-white text-sm text-gray-400`}>
                              <span style={{ marginLeft: '3px', marginRight: '4px' }}>
                                <svg width="17px" height="17px" viewBox="0 0 0.48 0.48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="m0.38 0.1 -0.28 0.28" stroke="#f56e79" strokeWidth="0.04000000000000001" strokeLinecap="round" />
                                  <path d="m0.1 0.1 0.28 0.28" stroke="#f56e79" strokeWidth="0.04000000000000001" strokeLinecap="round" />
                                </svg>
                              </span>
                              <span className='text-gray-600'>{feature}</span>
                            </li>
                          ))}
                        </ul>
                        {plan.type === TYPE_HEADSHOTS && (
                          <>
                            <h3 className="text-sm bold mb-4">{plan.bonus}</h3>
                            <ul className="space-y-2 mb-5">
                              {plan.bonusFeatures.map((feature, index) => (
                                <li key={index} className={`flex items-center text-white text-sm text-gray-400`}>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M17.0964 7.39004L9.9364 14.3L8.0364 12.27C7.6864 11.94 7.1364 11.92 6.7364 12.2C6.3464 12.49 6.2364 13 6.4764 13.41L8.7264 17.07C8.9464 17.41 9.3264 17.62 9.7564 17.62C10.1664 17.62 10.5564 17.41 10.7764 17.07C11.1364 16.6 18.0064 8.41004 18.0064 8.41004C18.9064 7.49004 17.8164 6.68004 17.0964 7.38004V7.39004Z"
                                      fill="#72ff9a"
                                    />
                                  </svg>
                                  <span>{feature}</span>
                                </li>
                              ))}
                            </ul>
                          </>
                        )}

                        {/* Subscribe button */}
                        {plan.type === TYPE_HEADSHOTS ? (
                          <>
                            <button
                              onClick={() => startHeadshots()}
                              className={`w-full block text-center ${plan.highlighted ? 'bg-pink-500 hover:bg-pink-500' : 'btn-bg-gray-500'} text-white font-bold py-3 px-4 rounded transition duration-300`}
                            >
                              {plan.calledBuy}
                            </button>
                            <div className="text-sm text-white mt-3 flex justify-center bold">{'No subscription'}</div>
                          </>
                        ) : (
                          <>
                            {!plan.isFree && userData.type && userData.type === plan.type ?
                              (
                                <>
                                  <div
                                    className={`w-full block text-center text-white font-bold py-3 px-4 rounded transition duration-300`}
                                    style={{ background: '#98305626' }}
                                  >
                                    Current Plan
                                  </div>
                                  <a className="text-sm text-white mt-3 flex justify-center bold" href="https://reok.lemonsqueezy.com/billing" target="_blank">Cancel this plan ↗</a>
                                </>
                              ) : (
                                <>
                                  <a
                                    className={`w-full block text-center ${plan.highlighted ? 'bg-pink-500 hover:bg-pink-500' : 'btn-bg-gray-500'} text-white font-bold py-3 px-4 rounded transition duration-300`}
                                    href={`${plan.checkout}?checkout[email]=${userData.email}&aff=${affCode}&checkout[custom][email]=${userData.email}`}
                                  >
                                    {plan.isFree ? 'Buy ↗' : 'Subscribe ↗'}
                                  </a>
                                  <div className="text-sm text-white mt-3 flex justify-center bold">{plan.isFree ? 'No subscription' : 'Cancel anytime'}</div>
                                </>
                              )}
                          </>
                        )}
                      </div>
                    </div>
                    )}
                    {plan.type === "enterprise" && (
                    <div className={`text-white p-6 rounded-xl max-w-sm ${plan.highlighted ? 'border-1 border-white-300' : ''}`} style={{ background: '#1e1e1e' }}>
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-2xl font-bold text-white">{plan.name}</h2>
                        <span
                          className="text-white px-2 py-1 rounded-full text-sm font-semibold"
                          style={{ border: '1px solid #fc5185', background: 'none', cursor: 'pointer', borderRadius: '6px' }}
                        >
                          {plan.credits}
                          <svg
                            style={{ marginTop: '-2px', marginRight: '-2px' }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 32 32"
                            fill="#ffc337"
                          >
                            <path d="M17.333 4v9.333h8L14.666 28v-9.333H6.667z" />
                          </svg>
                        </span>
                      </div>
                      <p className="text-gray-400 mb-6 text-sm">
                        {plan.description}
                      </p>
                      <div className="text-white rounded-lg">
                        <a
                          className={`w-full block text-center ${plan.highlighted ? 'bg-pink-500 hover:bg-pink-500' : 'btn-bg-gray-500'} text-white font-bold py-3 px-4 rounded transition duration-300`}
                          href={`https://www.facebook.com/profile.php?id=61555339043629`}
                          target='_blank'
                        >
                          Contact us
                        </a>
                      </div>
                    </div>
                    )}
                  </>
                ))}
              </div>
            </div>
            <div className="flex justify-center mt-4">
              <img src="/images/checkout-bank.png" width="350px" alt="checkout" />
            </div>
          </div>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default NewPricing;
