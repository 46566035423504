import React, { Component } from 'react';
import { connect } from 'react-redux'; // Import connect from react-redux
import LandingPage from '../../components/LandingPage';

class LandingPageContainer extends Component {
  render() {
    const { user, firebase } = this.props; // Access user from props

    return <LandingPage userData={user} engine={firebase}/>; // Pass user data as prop to LandingPage
  }
}

// Map state to props
const mapStateToProps = state => ({
  user: state.user, // Access user from Redux state
});

// Connect LandingPageContainer to Redux store
export default connect(mapStateToProps)(LandingPageContainer);