import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeStep: 0, // Starting at welcome screen
  stepData: {
    step0: {
      gender: null,
      age: '',
      eyeColor: null,
      hairColor: null,
      ethnicity: null,
    },
    step1: {
      selectedImages: [],
      uploadedSelfies: [],
    },
    step2: {
      paymentDetails: null,
    }
  },
  currentPhotoPackId: null, // Track which style/pack user is working on
  isLoadingPhotos: false,
  isUploadingPhotos: false,
  error: null,
};

const photoStepSlice = createSlice({
  name: 'photoStep',
  initialState,
  reducers: {
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },

    updateStepData: (state, action) => {
      const { step, data } = action.payload;
      state.stepData[`step${step}`] = {
        ...state.stepData[`step${step}`],
        ...data
      };
    },

    // Set which photo pack/style user is working on
    setCurrentPhotoPack: (state, action) => {
      // If different pack selected, reset everything
      if (state.currentPhotoPackId !== action.payload) {
        state.activeStep = 0;
        state.stepData = initialState.stepData;
        state.currentPhotoPackId = action.payload;
      }
    },

    // Reset everything (used when switching styles)
    resetAll: (state) => {
      return initialState;
    },

    // Loading states
    setLoading: (state, action) => {
      state.isLoadingPhotos = action.payload;
    },
    
    setUploading: (state, action) => {
      state.isUploadingPhotos = action.payload;
    },

    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { 
  setActiveStep, 
  updateStepData, 
  setCurrentPhotoPack,
  resetAll,
  setLoading,
  setUploading,
  setError 
} = photoStepSlice.actions;

export default photoStepSlice.reducer;