import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import MessageIcon from '@mui/icons-material/Message';
import { Camera } from '@mui/icons-material';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import FeedbackIcon from '@mui/icons-material/Feedback';

const RewardIcon = () => {
    return (
    <div className="flex justify-center">
        <svg width="24" height="24" viewBox="0 0 0.875 0.875" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" className="bounce-rotate-animation">
        <path fill="#d32f2f" d="M0.729 0.813H0.146a0.042 0.042 0 0 1 -0.042 -0.042V0.354h0.667v0.417c0 0.023 -0.019 0.042 -0.042 0.042" />
        <path fill="#f44336" d="M0.813 0.354H0.063v-0.125C0.063 0.206 0.081 0.188 0.104 0.188h0.667c0.023 0 0.042 0.019 0.042 0.042z" />
        <path fill="#ff8f00" d="M0.396 0.354h0.083V0.813H0.396zm0.125 -0.292 -0.125 0.125h0.083l0.125 -0.125z" />
        <path fill="#ffc107" d="M0.396 0.354h0.083V0.188l-0.125 -0.125H0.271l0.125 0.125z" />
        </svg>
  </div>
    )
}
const actions = [
    // { icon: <FeedbackIcon sx={{ color: '#000' }}/>, name: 'Copy', onClick: () => {
    //     window.open('https://reok.pro/affiliate', '_blank');
    // } },
    { icon: <SupportAgentIcon sx={{ color: '#000' }}/>, name: 'We are always here to answer all your questions!', onClick: () => {
        window.open('https://www.facebook.com/profile.php?id=61555339043629', '_blank');
    } },
    { icon: <RewardIcon />, name: 'Receive a 30% commission on payments for all customers you refer to us!', onClick: () => {
        window.open('https://reok.pro/affiliate', '_blank');
    } },
];

export default function OpenIconSpeedDial() {
    return (
        <Box sx={{ height: 0 }}>
            <SpeedDial
                ariaLabel="SpeedDial openIcon example"
                sx={{ 
                    position: 'fixed', 
                    bottom: 46, 
                    right: 16,
                    '& .MuiSpeedDial-fab': {
                        bgcolor: '#1E1E1E',
                        position: 'relative', // Added to position the dot
                        '&:hover': {
                            bgcolor: '#fc5185'
                        },
                        '&::after': {  // Added dot styling
                            content: '""',
                            position: 'absolute',
                            width: '8px',
                            height: '8px',
                            backgroundColor: '#7cf681', // Green color
                            borderRadius: '50%',
                            top: '10px',
                            right: '10px',
                        }
                    }
                }}
                icon={<MessageIcon />}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => {
                            action.onClick();
                        }}
                    />
                ))}
            </SpeedDial>
        </Box>
    );
}